import React, { useEffect, useState } from "react";
import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import useFetch from "../../hooks/useFetch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Getallteacher from "./Getallteacher";

const Assign = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const code = location.pathname.split("/")[4];
  // const nowid = location.state ? location.state.nowid : null; // Access state from location object
  console.log("nowid nowid", props.nowid);
  console.log("id", id);
  const [teacherData, setTeacherData] = useState(null);
  const { data, loading, error } = useFetch(
    `https://api.studentrays.com/api/users/${id}`
  );
  const { data: eventResponse } = useFetch(
    `https://api.studentrays.com/api/events/${data.teacherAssigned}`
  );
  const { data: teacherResponse } = useFetch(
    `https://api.studentrays.com/api/users/${eventResponse.teacherid}`
  );
  // const eventResponse = await axios.get(`/events/${data.teacherAssigned}`);
  console.log("teacherAssignedResponse", eventResponse);
  console.log("teacherResponse", teacherResponse);
  console.log("seeData", data);

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButton">Edit</div>
            <h1 className="title">Information</h1>
            <div className="item">
              <img
                // src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                src={data.img}
                alt=""
                className="itemImg"
              />
              <div className="details">
                {/* <h1 className="itemTitle">Jane Doe</h1> */}
                <h1 className="itemTitle">{data.username}</h1>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{data.email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">{data.phone}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Grade:</span>
                  <span className="itemValue">{data.grade}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">State:</span>
                  <span className="itemValue">{data.state}</span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="right">
            <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" />
          </div> */}
        </div>
        <div className="bottom">
          {data && data.batch ? (
            <span>
              Current Student batch is <b>{data.batch[0]}</b> <br />
              Current Assigned teacher is <b>{teacherResponse.username}</b>
            </span>
          ) : null}
          <h2>Select Student Subject than Assign Teacher</h2>
          <h3>Assign Teacher</h3>
          {/* <button onClick={teacherFunction}>Click me</button> */}
          {data && data.batch ? (
            <Getallteacher
              id={id}
              data={data}
              batch={data.batch[0]}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Assign;
