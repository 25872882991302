import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import useFetch from "../../hooks/useFetch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const Single = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  console.log("id", id);
  const { data, loading, error } = useFetch(`https://api.studentrays.com/api/users/${id}`);
  console.log("seeData", data);

  const handleCreateTeacher = async () => {
    console.log("made him teacher");
    // setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    const credentials = {
      isStudent: "false",
      isCounsellor: "false",
      isAdmin: "false",
      isTeacher: "true",
    };

    try {
      const res = await axios.put(`https://api.studentrays.com/api/users/${data._id}`, credentials); // Send a request to your backend logout endpoint
      if (res.status === 200) {
        console.log("create Teacher Success");
        navigate("/users/");
      } else {
        console.log("create Teacher Failed");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleCreateAdmin = async () => {
    console.log("made him teacher");
    // setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    const credentials = {
      isStudent: "false",
      isCounsellor: "false",
      isAdmin: "true",
      isTeacher: "false",
    };

    try {
      const res = await axios.put(`https://api.studentrays.com/api/users/${data._id}`, credentials); // Send a request to your backend logout endpoint
      if (res.status === 200) {
        console.log("create Teacher Success");
        navigate("/users/"); // Send a request to
      } else {
        console.log("create Teacher Failed");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleCreateCounsellor = async () => {
    console.log("made him teacher");
    // setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    const credentials = {
      isStudent: "false",
      isCounsellor: "true",
      isAdmin: "false",
      isTeacher: "false",
    };

    try {
      const res = await axios.put(`https://api.studentrays.com/api/users/${data._id}`, credentials); // Send a request to your backend logout endpoint
      if (res.status === 200) {
        console.log("create Counsellor Success");
        navigate("/users/"); // Send a request to
      } else {
        console.log("create Counsellor Failed");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleCreateStudent = async () => {
    console.log("made him teacher");
    // setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    const credentials = {
      isStudent: "true",
      isCounsellor: "false",
      isAdmin: "false",
      isTeacher: "false",
    };

    try {
      const res = await axios.put(`https://api.studentrays.com/api/users/${data._id}`, credentials); // Send a request to your backend logout endpoint
      if (res.status === 200) {
        console.log("create Student Success");
        navigate("/users/"); // Send a request to
      } else {
        console.log("create Student Failed");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButton">Edit</div>
            <h1 className="title">Information</h1>
            <div className="item">
              <img
                // src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                src={data.img}
                alt=""
                className="itemImg"
              />
              <div className="details">
                {/* <h1 className="itemTitle">Jane Doe</h1> */}
                <h1 className="itemTitle">{data.username}</h1>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{data.email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">{data.phone}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Grade:</span>
                  <span className="itemValue">{data.grade}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Country:</span>
                  <span className="itemValue">{data.country}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" />
          </div>
        </div>
        <div className="bottom">
          {/* <h1 className="title">Last Transactions</h1>
          <List data={data} id={id} /> */}

          <TableContainer component={Paper} className="table">
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/* <TableCell className="tableCell">ID</TableCell> */}
                  <TableCell className="tableCell">Username</TableCell>
                  {/* <TableCell className="tableCell">Email</TableCell> */}
                  <TableCell className="tableCell">IsStudent</TableCell>
                  <TableCell className="tableCell">IsAdmin</TableCell>
                  <TableCell className="tableCell">IsTeacher</TableCell>
                  <TableCell className="tableCell">IsCounsellor</TableCell>
                  <TableCell className="tableCell">New Student</TableCell>
                  <TableCell className="tableCell">New Teacher</TableCell>
                  <TableCell className="tableCell">New Admin</TableCell>
                  <TableCell className="tableCell">New Counsellor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow >
                  {/* <TableCell className="tableCell">{data._id}</TableCell> */}
                  <TableCell className="tableCell">
                    <div className="cellWrapper">{data.username}</div>
                  </TableCell>
                  {/* <TableCell className="tableCell">{data.email}</TableCell> */}
                  <TableCell className="tableCell">
                    {data.isStudent ? "true" : "false"}
                  </TableCell>
                  <TableCell className="tableCell">
                    {data.isAdmin ? "true" : "false"}
                  </TableCell>
                  <TableCell className="tableCell">
                    {data.isTeacher ? "true" : "false"}
                  </TableCell>
                  <TableCell className="tableCell">
                    {data.isCounsellor ? "true" : "false"}
                  </TableCell>
                  <TableCell className="tableCell">
                    <button type="submit" onClick={handleCreateStudent}>
                      Make Student
                    </button>
                  </TableCell>
                  <TableCell className="tableCell">
                    <button type="submit" onClick={handleCreateTeacher}>
                      Make Teacher
                    </button>
                  </TableCell>
                  <TableCell className="tableCell">
                    <button type="submit" onClick={handleCreateAdmin}>
                      Make Admin
                    </button>
                  </TableCell>
                  <TableCell className="tableCell">
                    <button type="submit" onClick={handleCreateCounsellor}>
                      Make Counsellor
                    </button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default Single;
