
// import "./single.scss";
// import React, { useState, useEffect } from "react";
// import Sidebar from "../../components/sidebar/Sidebar";
// import Navbar from "../../components/navbar/Navbar";
// import useFetch from "../../hooks/useFetch";
// import { Link, useLocation } from "react-router-dom";
// import axios from "axios";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import Topic from "./Topic";
// import Topicquestion from "./Topicquestion";

// const AllTopic = () => {
//   const location = useLocation();
//   const detail_id = location.pathname.split("/")[5];
//   const [editMode, setEditMode] = useState(false);
//   const [editedChapterName, setEditedChapterName] = useState("");

//   const { data, loading, error } = useFetch(
//     `https://api.studentrays.com/api/details/${detail_id}`
//   );
//   const [allminiTopics, setAllMiniTopics] = useState([]);
//   const [finalquestions, setFinalQuestions] = useState([]);

//   useEffect(() => {
//     if (data.miniTopics) {
//       setAllMiniTopics(data.miniTopics);
//     }
//     if (data.questions) {
//       setFinalQuestions(data.questions);
//     }
//   }, [data]);

//   const handleEditClick = () => {
//     setEditMode(true);
//     setEditedChapterName(data.SubTopic);
//   };

//   const handleSaveChanges = async () => {
//     try {
//       const updatedData = {
//         SubTopic: editedChapterName,
//       };

//       const response = await axios.put(
//         `https://api.studentrays.com/api/details/${data._id}`,
//         updatedData
//       );
//       console.log("Updated data:", response.data);
//       setEditedChapterName(response.data.SubTopic);
//       data.SubTopic = response.data.SubTopic;
//       setEditMode(false);
//     } catch (error) {
//       console.error("Error updating data:", error);
//     }
//   };

//   const handleDeleteTopic = async (topicId) => {
//     // setAllMiniTopics(allminiTopics.filter((id) => id !== topicId));
//     try {
//       await axios.delete(
//         `https://api.studentrays.com/api/details/${data._id}/${topicId}`
//       );
//       setAllMiniTopics(allminiTopics.filter((id) => id !== topicId));
//     } catch (error) {
//       console.error("Error deleting topic:", error);
//       alert("Failed to delete topic. Please try again.");
//     }
//   };

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error.message}</div>;

//   return (
//     <div className="single">
//       <Sidebar />
//       <div className="singleContainer">
//         <Navbar />

//         <div className="top">
//           <div className="left">
//             <div className="editButton" onClick={handleEditClick}>
//               Edit
//             </div>
//             <div className="item">
//               <Link to={`/datas/newsession/${data._id}`} className="link">
//                 Add New Session
//               </Link>
//               <div className={`details ${editMode ? "editMode" : ""}`}>
//                 {editMode ? (
//                   <input
//                     className="editInput"
//                     type="text"
//                     value={editedChapterName}
//                     onChange={(e) => setEditedChapterName(e.target.value)}
//                   />
//                 ) : (
//                   <h1 className="itemTitle">{data.SubTopic}</h1>
//                 )}
//                 <div className="detailItem">
//                   <span className="itemKey">Id:</span>
//                   <span className="itemValue">{data._id}</span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {editMode && <button onClick={handleSaveChanges}>Save Changes</button>}
//         <TableContainer component={Paper} className="table">
//           <Table sx={{ minWidth: 350 }} aria-label="simple table">
//             <TableHead>
//               <TableRow>
//                 <TableCell className="tableCell">Sub Topic Name</TableCell>
//                 <TableCell className="tableCell">ID</TableCell>
//                 <TableCell className="tableCell">Text</TableCell>
//                 <TableCell className="tableCell">View</TableCell>
//                 <TableCell className="tableCell">Delete</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {allminiTopics &&
//                 allminiTopics.map((onetopic) => (
//                   <Topic
//                     key={onetopic}
//                     chid={data._id}
//                     id={onetopic}
//                     onDelete={handleDeleteTopic}
//                   />
//                 ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         <Link to={`/datas/newallquestion/${data._id}`} className="link">
//           Add New Questions
//         </Link>
//         <Topicquestion data={data} />
//       </div>
//     </div>
//   );
// };

// export default AllTopic;
import "./single.scss";
import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import useFetch from "../../hooks/useFetch";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Topic from "./Topic";
import Topicquestion from "./Topicquestion";

const AllTopic = () => {
  const location = useLocation();
  const detail_id = location.pathname.split("/")[5];
  const [editMode, setEditMode] = useState(false);
  const [editedChapterName, setEditedChapterName] = useState("");

  const { data, loading, error } = useFetch(
    `https://api.studentrays.com/api/details/${detail_id}`
  );
  const [allminiTopics, setAllMiniTopics] = useState([]);
  const [finalquestions, setFinalQuestions] = useState([]);

  useEffect(() => {
    if (data.miniTopics) {
      setAllMiniTopics(data.miniTopics);
    }
    if (data.questions) {
      setFinalQuestions(data.questions);
    }
  }, [data]);

  const handleEditClick = () => {
    setEditMode(true);
    setEditedChapterName(data.SubTopic);
  };

  const handleSaveChanges = async () => {
    try {
      const updatedData = {
        SubTopic: editedChapterName,
      };

      const response = await axios.put(
        `https://api.studentrays.com/api/details/${data._id}`,
        updatedData
      );
      console.log("Updated data:", response.data);
      setEditedChapterName(response.data.SubTopic);
      data.SubTopic = response.data.SubTopic;
      setEditMode(false);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleDeleteTopic = async (topicId) => {
    // try {
    //   await axios.delete(
    //     `https://api.studentrays.com/api/details/${data._id}/${topicId}`
    //   );
      setAllMiniTopics(allminiTopics.filter((id) => id !== topicId));
    // } catch (error) {
    //   console.error("Error deleting topic:", error);
    //   alert("Failed to delete topic. Please try again.");
    // }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />

        <div className="top">
          <div className="left">
            <div className="editButton" onClick={handleEditClick}>
              Edit
            </div>
            <div className="item">
              <Link to={`/datas/newsession/${data._id}`} className="link">
                Add New Session
              </Link>
              <div className={`details ${editMode ? "editMode" : ""}`}>
                {editMode ? (
                  <input
                    className="editInput"
                    type="text"
                    value={editedChapterName}
                    onChange={(e) => setEditedChapterName(e.target.value)}
                  />
                ) : (
                  <h1 className="itemTitle">{data.SubTopic}</h1>
                )}
                <div className="detailItem">
                  <span className="itemKey">Id:</span>
                  <span className="itemValue">{data._id}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {editMode && <button onClick={handleSaveChanges}>Save Changes</button>}
        <TableContainer component={Paper} className="table">
          <Table sx={{ minWidth: 350 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableCell">Sub Topic Name</TableCell>
                <TableCell className="tableCell">ID</TableCell>
                <TableCell className="tableCell">Text</TableCell>
                <TableCell className="tableCell">View</TableCell>
                <TableCell className="tableCell">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allminiTopics &&
                allminiTopics.map((onetopic) => (
                  <Topic
                    key={onetopic}
                    chid={data._id}
                    id={onetopic}
                    onDelete={handleDeleteTopic}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Link to={`/datas/newallquestion/${data._id}`} className="link">
          Add New Questions
        </Link>
        <Topicquestion data={data} />
      </div>
    </div>
  );
};

export default AllTopic;
