import React from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Datatable from "../../components/datatable/Datatable"
import Datatabledetail from "../../components/datatable/Datatabledetail"

const AllDetail = (props) => {
    const location = useLocation();
    const id = location.pathname.split("/")[2];
    const id1 = location.pathname.split("/")[3];
    console.log("id",id);
    console.log("id1",id1);
    const path = "users"
    const { data, loading, error } = useFetch(`https://api.studentrays.com/api/${path}`);
    console.log("userss",data);
    console.log('Type of id and grade property:', typeof id, typeof data[0]?.grade);

    const filteredData = data && data.filter((row) => row.grade.trim() === id.trim());
    var allfilteredData = filteredData && filteredData.filter((rows) => rows.section.trim() === id1.trim());
    
    // var filteredData = data && data.filter((row) => {
    //     console.log("id:", id);
    //     console.log("row.grade:", row.grade);
    //     return row.grade.trim() === id.trim();
    //   });
    console.log("filteredData",filteredData);
    console.log("allfilteredData",allfilteredData);

  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <Datatabledetail columns={props.columns} allfilteredData={allfilteredData} />
      </div>
    </div>
  )
}

export default AllDetail
