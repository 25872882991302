import React from "react";

import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import AssignteacherDatatable from "../../components/datatable/AssignteacherDatatable"
import "./assignteacher.css";
const Assignteacher = ({columns }) => {
  const location = useLocation();
  // const nowid = location.state ? location.state.nowid : null; // Access state from location object
  // console.log("nowid", nowid);
  return (
    <>
      <div className="assign">
        <Sidebar />
        <div className="listContainer">
          <Navbar />
          <AssignteacherDatatable columns={columns} />
        </div>
      </div>
    </>
  );
};

export default Assignteacher;
