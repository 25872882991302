// import "./chapterdetail.scss";
// import Sidebar from "../../components/sidebar/Sidebar";
// import Navbar from "../../components/navbar/Navbar";
// import Chart from "../../components/chart/Chart";
// import List from "../../components/table/Table";
// import useFetch from "../../hooks/useFetch";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import TableCell from "@mui/material/TableCell";
// import TableRow from "@mui/material/TableRow";
// import { useEffect, useState } from "react";
// const Subtopic = (props) => {
//   //   const location = useLocation();
//   //   const path = location.pathname.split("/")[1];
//   const { data, loading, error } = useFetch(`https://api.studentrays.com/api/details/${props.id}`);
//   console.log("seethisData", data);
//   const [isDeleted, setIsDeleted] = useState(false);
//   console.log("chid", props.chid);
//   console.log("detailid", props.id);
//   // const handleDelete = async (id) => {
//   //   try {
//   //     await axios.delete(`https://api.studentrays.com/api/details/${props.chid}/${props.id}`);
//   //     console.log("id", props.id);
//   //     console.log("chid", props.chid);
//   //   } catch (err) {}
//   // };
//   const handleDelete = async () => {
//     if (window.confirm("Are you sure you want to delete this chapter?")) {
//       try {
//         await axios.delete(`https://api.studentrays.com/api/details/${props.chid}/${props.id}`);
//         console.log("Chapter deleted successfully");
//         // setIsDeleted(true);
//         // if (onDelete) {
//         //   onDelete(id);
//         // }
//       } catch (err) {
//         console.error("Error deleting chapter:", err);
//         alert("Failed to delete chapter. Please try again.");
//       }
//     }
//   };

//   if (loading) return <TableRow><TableCell colSpan={4}>Loading...</TableCell></TableRow>;
//   if (error) return <TableRow><TableCell colSpan={4}>Error: {error.message}</TableCell></TableRow>;
//   // if (isDeleted) return null;
//   return (
//     <TableRow>
//       <TableCell className="tableCell">
//         <div className="cellWrapper">{data.SubTopic}</div>
//       </TableCell>
//       <TableCell className="tableCell">
//         <div className="cellWrapper">{data._id}</div>
//       </TableCell>
//       <TableCell className="tableCell">
//         <div className="cellWrapper">{data.image}</div>
//       </TableCell>
//       <TableCell className="tableCell">
//         <Link
//           to={`/datas/chapter/topic/${props.chid}/${data._id}`}
//           style={{ textDecoration: "none" }}
//         >
//           <div className="chapterviewButton">View</div>
//         </Link>
//       </TableCell>
//       <TableCell className="tableCell">
//         <div
//           className="chapterdeleteButton"
//           onClick={() => handleDelete(data._id)}
//         >
//           Delete
//         </div>
//       </TableCell>
//     </TableRow>

//   );
// };

// export default Subtopic;
import "./chapterdetail.scss";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import useFetch from "../../hooks/useFetch";

const Subtopic = ({ chid, id, onDelete }) => {
  const { data, loading, error } = useFetch(`https://api.studentrays.com/api/details/${id}`);
  const [isDeleted, setIsDeleted] = useState(false);

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this chapter?")) {
      try {
        await axios.delete(`https://api.studentrays.com/api/details/${chid}/${id}`);
        console.log("Chapter deleted successfully");
        setIsDeleted(true);
        onDelete(id);
      } catch (err) {
        console.error("Error deleting chapter:", err);
        alert("Failed to delete chapter. Please try again.");
      }
    }
  };

  if (loading) return <TableRow><TableCell colSpan={4}>Loading...</TableCell></TableRow>;
  if (error) return <TableRow><TableCell colSpan={4}>Error: {error.message}</TableCell></TableRow>;
  if (isDeleted) return null;

  return (
    <TableRow>
      <TableCell className="tableCell">
        <div className="cellWrapper">{data.SubTopic}</div>
      </TableCell>
      <TableCell className="tableCell">
        <div className="cellWrapper">{data._id}</div>
      </TableCell>
      <TableCell className="tableCell">
        <div className="cellWrapper">{data.image}</div>
      </TableCell>
      <TableCell className="tableCell">
        <Link to={`/datas/chapter/topic/${chid}/${data._id}`} style={{ textDecoration: "none" }}>
          <div className="chapterviewButton">View</div>
        </Link>
      </TableCell>
      <TableCell className="tableCell">
        <div className="chapterdeleteButton" onClick={handleDelete}>
          Delete
        </div>
      </TableCell>
    </TableRow>
  );
};

export default Subtopic;
