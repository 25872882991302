// import "./chapterdetail.scss";
// import Sidebar from "../../components/sidebar/Sidebar";
// import Navbar from "../../components/navbar/Navbar";
// import Chart from "../../components/chart/Chart";
// import List from "../../components/table/Table";
// import useFetch from "../../hooks/useFetch";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import TableCell from "@mui/material/TableCell";
// import TableRow from "@mui/material/TableRow";
// import { useEffect, useState } from "react";

// const Chapterdetail = (props) => {
//   const location = useLocation();
//   const path = location.pathname.split("/")[1];
//   const { data, loading, error } = useFetch(`https://api.studentrays.com/api/chapters/${props.id}`);
//   console.log("seethisData", data);
//   console.log("seethisdetails", data.details);
//   //   console.log("detailData",data.chapterdetails);

//   const handleDelete = async (id) => {
//     try {
//       await axios.delete(`https://api.studentrays.com/api/chapters/${props.chid}/${props.id}`);
//       console.log("id", props.id);
//       console.log("chid", props.chid);
//     } catch (err) {}
//   };

//   return (
//     <TableRow>
//       <TableCell className="tableCell">
//         <div className="cellWrapper">{data.chapterName}</div>
//       </TableCell>
//       <TableCell className="tableCell">
//         <div className="cellWrapper">{data._id}</div>
//       </TableCell>
//       <TableCell className="tableCell">
//         <Link
//           to={`/datas/chapter/${props.chid}/${data._id}`}
//           style={{ textDecoration: "none" }}
//         >
//           <div className="chapterviewButton">View</div>
//         </Link>
//       </TableCell>
//       <TableCell className="tableCell">
//         <div
//           className="chapterdeleteButton"
//           onClick={() => handleDelete(data._id)}
//         >
//           Delete
//         </div>
//       </TableCell>
//     </TableRow>
//   );
// };

// export default Chapterdetail;
import "./chapterdetail.scss";
import useFetch from "../../hooks/useFetch";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";

const Chapterdetail = ({ chid, id, onDelete }) => {
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const { data, loading, error } = useFetch(`https://api.studentrays.com/api/chapters/${id}`);
  const [isDeleted, setIsDeleted] = useState(false);

  console.log("seethisData", data);
  console.log("seethisdetails", data.details);

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this chapter?")) {
      try {
        await axios.delete(`https://api.studentrays.com/api/chapters/${chid}/${id}`);
        console.log("Chapter deleted successfully");
        setIsDeleted(true);
        if (onDelete) {
          onDelete(id);
        }
      } catch (err) {
        console.error("Error deleting chapter:", err);
        alert("Failed to delete chapter. Please try again.");
      }
    }
  };

  if (loading) return <TableRow><TableCell colSpan={4}>Loading...</TableCell></TableRow>;
  if (error) return <TableRow><TableCell colSpan={4}>Error: {error.message}</TableCell></TableRow>;
  if (isDeleted) return null;

  return (
    <TableRow>
      <TableCell className="tableCell">
        <div className="cellWrapper">{data.chapterName}</div>
      </TableCell>
      <TableCell className="tableCell">
        <div className="cellWrapper">{data._id}</div>
      </TableCell>
      <TableCell className="tableCell">
        <Link
          to={`/datas/chapter/${chid}/${data._id}`}
          style={{ textDecoration: "none" }}
        >
          <div className="chapterviewButton">View</div>
        </Link>
      </TableCell>
      <TableCell className="tableCell">
        <div
          className="chapterdeleteButton"
          onClick={handleDelete}
        >
          Delete
        </div>
      </TableCell>
    </TableRow>
  );
};

export default Chapterdetail;