import "./single.scss";
import React, { useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import useFetch from "../../hooks/useFetch";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Topic from "./Topic";
import Finalquestion from "./Finalquestion";
const FinalTopic = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  // const ch_id = location.pathname.split("/")[3];
  // const detail_id = location.pathname.split("/")[4];
  const [editMode, setEditMode] = useState(false);
  const [editedChapterName, setEditedChapterName] = useState("");

  const location = useLocation();
  const ch_id = location.pathname.split("/")[4];
  const detail_id = location.pathname.split("/")[5];
  //   console.log("ch_id_", ch_id);
  //   console.log("detail_id_", detail_id);
  const { data, loading, error,reFetch } = useFetch(`https://api.studentrays.com/api/subtopics/${detail_id}`);
  console.log("data", data);
  // console.log("miniTopicdata", data.miniTopics);

  const allminiTopics = data.miniTopics;
  const finalquestions = data.questions;

  const handleEditClick = () => {
    setEditMode(true);
    setEditedChapterName(data.topic);
    // console.log("data.topic",data.topic);
  };

  const handleSaveChanges = async () => {
    try {
      // Prepare the updated data
      const updatedData = {
        topic: editedChapterName,
        // Add other fields as needed
      };
      console.log("updatedData",updatedData);

      // Send a PUT request to update the data
      const response = await axios.put(`https://api.studentrays.com/api/subtopics/${data._id}`, updatedData);

      // Handle the response as needed
      // console.log("Updated data:", response.data);

      // Perform any other actions needed after successful update
      setEditMode(false);
      if (response.status === 200 || response.status === 201) {
        alert("Data submitted successfully!");
        reFetch();
        // navigate(-1); // This will navigate to the previous page
      }
      // window.location.reload();
    } catch (error) {
      console.error("Error updating data:", error);
      // Handle errors as needed
    }
  };

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />

        <div className="top">
          <div className="left">
          <Link to={`/datas/newquestion/${data._id}`} className="link">
          Add New session Question
        </Link>
            <div className="editButton" onClick={handleEditClick}>
              Edit
            </div>
            <div className="item">
              {/* <Link to={`/datas/newsession/${data._id}`} className="link">
                Add New Session
              </Link> */}
              <div className={`details ${editMode ? "editMode" : ""}`}>
                {editMode ? (
                  <input
                    className="editInput"
                    type="text"
                    value={editedChapterName}
                    onChange={(e) => setEditedChapterName(e.target.value)}
                  />
                ) : (
                  <h1 className="itemTitle">{data.topic}</h1>
                )}
                <div className="detailItem">
                  <span className="itemKey">Id:</span>
                  <span className="itemValue">{data._id}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {editMode && <button onClick={handleSaveChanges}>Save Changes</button>} 
        <Finalquestion data={data} />
      </div>
    </div>
  );
};

export default FinalTopic;
