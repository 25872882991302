// import "./single.scss";
// import React, { useState } from "react";
// import Sidebar from "../../components/sidebar/Sidebar";
// import Navbar from "../../components/navbar/Navbar";
// import Chart from "../../components/chart/Chart";
// import List from "../../components/table/Table";
// import useFetch from "../../hooks/useFetch";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import Chapterdetail from "./Chapterdetail";
// import Subtopic from "./Subtopic";
// import axios from "axios";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";

// const SingleDetail = () => {
//   const location = useLocation();
//   const ch_id = location.pathname.split("/")[3];
//   const detail_id = location.pathname.split("/")[4];
//   const [editMode, setEditMode] = useState(false);
//   const [editedChapterName, setEditedChapterName] = useState("");

//   console.log("ch_id", ch_id);
//   console.log("detail_id", detail_id);
//   //   const { data, loading, error } = useFetch(`/datas/find/${id}`);
//   // const { data, loading, error } = useFetch(`/datas/find/${id}`);
//   const { data, loading, error } = useFetch(`https://api.studentrays.com/api/chapters/${detail_id}`);
//   console.log("detailedData", data);
//   //   console.log("detaile", data.details);
//   //   console.log("detailData", data.chapterdetails);
//   const singleDetail = data.details;
//   //   console.log(singleDetail);

//   const handleEditClick = () => {
//     setEditMode(true);
//     setEditedChapterName(data.chapterName);
//   };

//   const handleSaveChanges = async () => {
//     try {
//       // Prepare the updated data
//       const updatedData = {
//         chapterName: editedChapterName,
//         // Add other fields as needed
//       };

//       // Send a PUT request to update the data
//       const response = await axios.put(`https://api.studentrays.com/api/chapters/${data._id}`, updatedData);

//       // Handle the response as needed
//       console.log("Updated data:", response.data);

//       // Perform any other actions needed after successful update
//       setEditMode(false);
//       window.location.reload();
//     } catch (error) {
//       console.error("Error updating data:", error);
//       // Handle errors as needed
//     }
//   };
//   return (
//     <div className="single">
//       <Sidebar />
//       <div className="singleContainer">
//         <Navbar />

//         <div className="top">
//           <div className="left">
//             <div className="editButton" onClick={handleEditClick}>
//               Edit
//             </div>
//             <div className="item">
//               <Link to={`/datas/newsubtopic/${data._id}`} className="link">
//                 Add New Sub Topic
//               </Link>
//               <div className={`details ${editMode ? "editMode" : ""}`}>
//                 {editMode ? (
//                   <input
//                     className="editInput"
//                     type="text"
//                     value={editedChapterName}
//                     onChange={(e) => setEditedChapterName(e.target.value)}
//                   />
//                 ) : (
//                   <h1 className="itemTitle">{data.chapterName}</h1>
//                 )}

//                 {/* <div className="detailItem">
//                   <span className="itemKey">ChapterName:</span>
//                   <span className="itemValue">{data.chapterName}</span>
//                 </div> */}
//                 <div className="detailItem">
//                   <span className="itemKey">Id:</span>
//                   <span className="itemValue">{data._id}</span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {editMode && <button onClick={handleSaveChanges}>Save Changes</button>}

//         {/* <div className="detailItem"> */}
//           {/* <span className="itemKey">details:</span> */}
//           {/* <span className="itemValue"> */}
//             <TableContainer component={Paper} className="table">
//               <Table sx={{ minWidth: 350 }} aria-label="simple table">
//                 <TableHead>
//                   <TableRow>
//                     <TableCell className="tableCell">Sub Topic Name</TableCell>
//                     <TableCell className="tableCell">ID</TableCell>
//                     <TableCell className="tableCell">Image</TableCell>
//                     <TableCell className="tableCell">view</TableCell>
//                     <TableCell className="tableCell">Delete</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {singleDetail &&
//                     singleDetail.map((oneDetail) => (
//                       <Subtopic
//                         key={oneDetail}
//                         chid={data._id}
//                         id={oneDetail}
//                       />
//                     ))}
//                   {/* <Subtopic key={oneDetail} chid={data._id} id={oneDetail} /> */}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           {/* </span> */}
//         {/* </div> */}
//       </div>
//     </div>
//   );
// };

// export default SingleDetail;
import "./single.scss";
import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import useFetch from "../../hooks/useFetch";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Subtopic from "./Subtopic";

const SingleDetail = () => {
  const location = useLocation();
  const ch_id = location.pathname.split("/")[3];
  const detail_id = location.pathname.split("/")[4];
  const [editMode, setEditMode] = useState(false);
  const [editedChapterName, setEditedChapterName] = useState("");

  const { data, loading, error } = useFetch(`https://api.studentrays.com/api/chapters/${detail_id}`);
  const [subTopics, setSubTopics] = useState([]);

  useEffect(() => {
    if (data.details) {
      setSubTopics(data.details);
    }
  }, [data]);

  const handleEditClick = () => {
    setEditMode(true);
    setEditedChapterName(data.chapterName);
  };

  const handleSaveChanges = async () => {
    try {
      const updatedData = {
        chapterName: editedChapterName,
      };

      const response = await axios.put(`https://api.studentrays.com/api/chapters/${data._id}`, updatedData);
      console.log("Updated data:", response.data);

      setEditMode(false);
      window.location.reload();
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleDeleteSubtopic = (deletedId) => {
    setSubTopics(subTopics.filter((subTopicId) => subTopicId !== deletedId));
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />

        <div className="top">
          <div className="left">
            <div className="editButton" onClick={handleEditClick}>
              Edit
            </div>
            <div className="item">
              <Link to={`/datas/newsubtopic/${data._id}`} className="link">
                Add New Sub Topic
              </Link>
              <div className={`details ${editMode ? "editMode" : ""}`}>
                {editMode ? (
                  <input
                    className="editInput"
                    type="text"
                    value={editedChapterName}
                    onChange={(e) => setEditedChapterName(e.target.value)}
                  />
                ) : (
                  <h1 className="itemTitle">{data.chapterName}</h1>
                )}
                <div className="detailItem">
                  <span className="itemKey">Id:</span>
                  <span className="itemValue">{data._id}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {editMode && <button onClick={handleSaveChanges}>Save Changes</button>}

        <TableContainer component={Paper} className="table">
          <Table sx={{ minWidth: 350 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableCell">Sub Topic Name</TableCell>
                <TableCell className="tableCell">ID</TableCell>
                <TableCell className="tableCell">Image</TableCell>
                <TableCell className="tableCell">View</TableCell>
                <TableCell className="tableCell">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subTopics.map((oneDetail) => (
                <Subtopic
                  key={oneDetail}
                  chid={data._id}
                  id={oneDetail}
                  onDelete={handleDeleteSubtopic}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default SingleDetail;
