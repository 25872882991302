// import "./new.scss";
// import Sidebar from "../../components/sidebar/Sidebar";
// import Navbar from "../../components/navbar/Navbar";
// import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
// import { useState } from "react";
// import axios from "axios";
// import { Link, useLocation, useNavigate } from "react-router-dom";

// const NewSession = ({ inputs, title }) => {
//   const location = useLocation();
//   const idnew = location.pathname.split("/")[3];
//   const [info, setInfo] = useState({});

//   const handleChange = (e) => {
//     setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
//   };

//   const handleClick = async (e) => {
//     e.preventDefault();
//     try {
      
//       const newDetails = {
//         ...info
//       };

//       // await axios.post(`/chapters/${idnew}`, newDetails);
//       await axios.post("https://api.studentrays.com/api/subtopics/"+ idnew, newDetails);
//       const baseUrl = window.location.origin;
//       const urlToNavigate = `${baseUrl}/datas`;
//       window.location.href = urlToNavigate;
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   console.log(info);
//   return (
//     <div className="new">
//       <Sidebar />
//       <div className="newContainer">
//         <Navbar />
//         <div className="top">
//           <h1>{title}</h1>
//         </div>
//         <div className="bottom">
//           {/* <div className="left">
//             <img
//               src={
//                 file
//                   ? URL.createObjectURL(file)
//                   : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
//               }
//               alt=""
//             />
//           </div> */}
//           <div className="right">
//             <form>
//               {/* <div className="formInput">
//                 <label htmlFor="file">
//                   Image: <DriveFolderUploadOutlinedIcon className="icon" />
//                 </label>
//                 <input
//                   type="file"
//                   id="file"
//                   onChange={(e) => setFile(e.target.files[0])}
//                   style={{ display: "none" }}
//                 />
//               </div> */}

//               {inputs.map((input) => (
//                 <div className="formInput" key={input.id}>
//                   <label>{input.label}</label>
//                   <input
//                     onChange={handleChange}
//                     type={input.type}
//                     placeholder={input.placeholder}
//                     id={input.id}
//                   />
//                 </div>
//               ))}
//               <button onClick={handleClick}>Send</button>
//             </form>
           
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default NewSession;

import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const NewSession = ({ inputs, title }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const idnew = location.pathname.split("/")[3];
  const [info, setInfo] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    setErrors((prev) => ({ ...prev, [e.target.id]: '' }));
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors = {};

    inputs.forEach((input) => {
      if (!info[input.id] || info[input.id].trim() === '') {
        newErrors[input.id] = "This field is required";
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const newDetails = { ...info };
        const response = await axios.post("https://api.studentrays.com/api/subtopics/" + idnew, newDetails);
        
        if (response.status === 200 || response.status === 201) {
          alert("Data submitted successfully!");
          navigate(-1); // This will navigate to the previous page
        }
      } catch (err) {
        console.log(err);
        alert("An error occurred while submitting the data.");
      }
    } else {
      console.log("Form validation failed");
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form onSubmit={handleSubmit}>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    onChange={handleChange}
                    type={input.type}
                    placeholder={input.placeholder}
                    id={input.id}
                    required
                  />
                  {errors[input.id] && <span className="error">{errors[input.id]}</span>}
                </div>
              ))}
              <button type="submit">Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSession;