import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

const Detail = () => {
  const [selectedValue, setSelectedValue] = useState(); // Set the default value here
  const [selectedsectionValue, setSelectedsectionValue] = useState(); // Set the default value here
  const [selectedValueteacher, setSelectedteacherValue] = useState(); // Set the default value here
  const [selectedsectionValueteacher, setSelectedsectionteacherValue] =
    useState(); // Set the default value here
  const navigate = useNavigate();

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    console.log("Selected value: ", value);
  };
  const handleSelectChangesection = (event) => {
    const svalue = event.target.value;
    setSelectedsectionValue(svalue);
    console.log("Selected value: ", svalue);
  };
  const handleSubmit = () => {
    console.log("selectedValue: ", selectedValue);
    console.log("selectedsectionValue: ", selectedsectionValue);
    navigate(`${selectedsectionValue}/${selectedValue}`);
  };

  const handleSelectChangeteacher = (event) => {
    const tvalue = event.target.value;
    setSelectedteacherValue(tvalue);
    console.log("Selected value: ", tvalue);
  };
  const handleSelectChangesectionteacher = (event) => {
    const tsvalue = event.target.value;
    setSelectedsectionteacherValue(tsvalue);
    console.log("Selected value: ", tsvalue);
  };
  const handleteacherSubmit = () => {
    console.log("selectedValue: ", selectedValueteacher);
    console.log("selectedsectionValue: ", selectedsectionValueteacher);
    // navigate(`teachers/${selectedsectionValue}/${selectedValue}`);
    const baseUrl = window.location.origin;
    const urlToNavigate = `${baseUrl}/teachers/${selectedsectionValueteacher}/${selectedValueteacher}`;
    window.location.href = urlToNavigate;
  };

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        {/* <Datatabledetail columns={props.columns} allfilteredData={allfilteredData} /> */}
        {/* </div>
    <div> */}
        {/* Detail: */}
        <label>
          Student:
          <select
            name="detail"
            onChange={handleSelectChangesection}
            value={selectedValue}
          >
            <option value={null}>None</option>

            <option value="6">6th std</option>
            <option value="7">7th std</option>
            <option value="8">8th std</option>
            <option value="9">9th std</option>
            <option value="10">10th std</option>
            <option value="11">11th std</option>
            <option value="12">12th std</option>
          </select>
        </label>
        <label>
          <select
            name="section"
            onChange={handleSelectChange}
            value={selectedValue}
          >
            <option value={null}>None</option>
            <option value="A">Section A</option>
            <option value="B">Section B</option>
            <option value="C">Section C</option>
          </select>
        </label>
        <button type="submit" onClick={handleSubmit}>
          Go
        </button>

        <label>
          Teacher:
          <select
            name="teacherdetail"
            onChange={handleSelectChangesectionteacher}
            value={selectedValueteacher}
          >
            <option value={null}>None</option>

            <option value="6">6th std</option>
            <option value="7">7th std</option>
            <option value="8">8th std</option>
            <option value="9">9th std</option>
            <option value="10">10th std</option>
            <option value="11">11th std</option>
            <option value="12">12th std</option>
          </select>
        </label>
        <label>
          <select
            name="section"
            onChange={handleSelectChangeteacher}
            value={selectedValueteacher}
          >
            <option value={null}>None</option>
            <option value="A">Section A</option>
            <option value="B">Section B</option>
            <option value="C">Section C</option>
          </select>
        </label>
        <button type="submit" onClick={handleteacherSubmit}>
          Go
        </button>
      </div>
    </div>
  );
};

export default Detail;
