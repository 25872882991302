import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";

const NewAllQuestion = ({ inputs, title }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const idnew = location.pathname.split("/")[3];
  const [info, setInfo] = useState({});
  const [options, setOptions] = useState(["", "", "", ""]);

  const handleChange = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleOptionChange = (index, value) => {
    setOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[index] = value;
      return newOptions;
    });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const newDetails = {
        ...info,
        options,
      };
      console.log("newDetails", newDetails);
      const responce = await axios.post(
        "https://api.studentrays.com/api/allquestions/" + idnew,
        newDetails
      );
      console.log("responce", responce);
      alert("Data submitted successfully!");
      navigate(-1);
      // const baseUrl = window.location.origin;
      // const urlToNavigate = `${baseUrl}/datas`;
      // window.location.href = urlToNavigate;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    onChange={handleChange}
                    type={input.type}
                    placeholder={input.placeholder}
                    id={input.id}
                  />
                </div>
              ))}
              {/* Add four new input fields for options */}
              <div className="formInput">
                <label>Option 1</label>
                <input
                  type="text"
                  placeholder="Option 1"
                  onChange={(e) => handleOptionChange(0, e.target.value)}
                />
              </div>
              <div className="formInput">
                <label>Option 2</label>
                <input
                  type="text"
                  placeholder="Option 2"
                  onChange={(e) => handleOptionChange(1, e.target.value)}
                />
              </div>
              <div className="formInput">
                <label>Option 3</label>
                <input
                  type="text"
                  placeholder="Option 3"
                  onChange={(e) => handleOptionChange(2, e.target.value)}
                />
              </div>
              <div className="formInput">
                <label>Option 4</label>
                <input
                  type="text"
                  placeholder="Option 4"
                  onChange={(e) => handleOptionChange(3, e.target.value)}
                />
              </div>
              <button onClick={handleClick}>Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAllQuestion;
