import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";
import Table from "../../components/table/Table";
import useFetch from "../../hooks/useFetch";
const Home = () => {
  const {
    data: userdata,
    loading,
    error,
  } = useFetch("https://api.studentrays.com/api/users/user/count");
  const { data: studentdata } = useFetch(
    "https://api.studentrays.com/api/users/student/count"
  );
  const { data: teacherdata } = useFetch(
    "https://api.studentrays.com/api/users/teacher/count"
  );
  const { data: studentgradedata } = useFetch(
    "https://api.studentrays.com/api/users/student/count/bygrade"
  );
  const { data: teachergradedata } = useFetch(
    "https://api.studentrays.com/api/users/student/count/bygrade"
  );
  const { data: studentpaid } = useFetch(
    "https://api.studentrays.com/api/users/student/paid"
  );
  const { data: studentunpaid } = useFetch(
    "https://api.studentrays.com/api/users/student/unpaid"
  );
  const { data: allData } = useFetch("https://api.studentrays.com/api/users/");
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <Widget
            type="user"
            amount={userdata.userCount}
            // onClick={() => handleWidgetClick("user")}
          />
          <Widget
            type="student"
            amount={studentdata.studentCount}
            // onClick={() => handleWidgetClick("student")}
          />
          <Widget
            type="teacher"
            amount={teacherdata.teacherCount}
            // onClick={() => handleWidgetClick("teacher")}
          />
          <Widget
            type="paid"
            amount={studentpaid.length}
            // onClick={() => handleWidgetClick("paid")}
          />
          <Widget
            type="unpaid"
            amount={studentunpaid.length}
            // onClick={() => handleWidgetClick("unpaid")}
          />
        </div>
        {/* <div className="widgets">
          <Widget type="user" />
          <Widget type="order" />
          <Widget type="earning" />
          <Widget type="balance" />
        </div> */}
        <div className="charts">
          {/* <Featured /> */}
          <Chart title="Last 6 Months (Revenue)" aspect={2 / 1} />
        </div>
        {/* <div className="listContainer">
          <div className="listTitle">Latest Transactions</div>
          <Table />
        </div> */}
      </div>
    </div>
  );
};

export default Home;
