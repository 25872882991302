import React from "react";
// import ReactDOM from "react-dom";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";
import { DarkModeContextProvider } from "./context/darkModeContext";
import { createRoot } from "react-dom/client";

const root = createRoot(document.getElementById("root")); // Replace 'root' with your root element's ID or reference
root.render(
  // <React.StrictMode>
    <AuthContextProvider>
      <DarkModeContextProvider>
        <App />
      </DarkModeContextProvider>
    </AuthContextProvider>
);
// {/* </React.StrictMode> */}
