import React from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Datatable from "../../components/datatable/Datatable"
import Datatabledetail from "../../components/datatable/Datatabledetail"

const AllteacherDetail = (props) => {
    const location = useLocation();
    const id = location.pathname.split("/")[2];
    const id1 = location.pathname.split("/")[3];
    console.log("id",id);
    console.log("id1",id1);
    const path = "users"
    const { data, loading, error } = useFetch(`https://api.studentrays.com/api/${path}`);
    console.log("userss",data);
    console.log('Type of id and grade property:', typeof id, typeof data[0]?.teacherSection);
    console.log('Type grade property:', data[0]?.teacherSection);

    const filteredteacher = data && data.filter((row) => row.isTeacher === true);
    const filteredData = filteredteacher && filteredteacher.filter((row) => row.grade.trim() === id.trim());
    // var allfilteredData = filteredData && filteredData.filter((rows) => rows.section.trim() === id1.trim());
    let allfilteredData = [];
    filteredteacher.forEach((teacher) => {
      // Check if the teacherSection array contains "B"
      if (teacher.teacherSection.includes(id1)) {
          // If "B" is present, add the teacher object to the allfilteredData array
          allfilteredData.push(teacher);
      }
  });
    
    // var filteredData = data && data.filter((row) => {
    //     console.log("id:", id);
    //     console.log("row.grade:", row.grade);
    //     return row.grade.trim() === id.trim();
    //   });
    console.log("filteredData",filteredData);
    console.log("allfilteredData",allfilteredData);

  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <Datatabledetail columns={props.columns} allfilteredData={allfilteredData} person="teacher" />
      </div>
    </div>
  )
}

export default AllteacherDetail
