
// import React, { useState } from "react";
// import "./single.scss";
// import Sidebar from "../../components/sidebar/Sidebar";
// import Navbar from "../../components/navbar/Navbar";
// import Chart from "../../components/chart/Chart";
// import List from "../../components/table/Table";
// import useFetch from "../../hooks/useFetch";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import Chapterdetail from "./Chapterdetail";
// import { Input } from "@mui/base/Input";
// import axios from "axios";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// const Single = () => {
//   const location = useLocation();
//   const id = location.pathname.split("/")[2];
//   const { data, loading, error } = useFetch(`https://api.studentrays.com/api/datas/find/${id}`);
//   const detail = data.chapterdetails;

//   const [editMode, setEditMode] = useState(false);
//   const [editedGrade, setEditedGrade] = useState("");
//   const [editedChapterCount, setEditedChapterCount] = useState("");
//   const [editedSubject, setEditedSubject] = useState("");
//   console.log("editedGrade:", editedGrade);
//   console.log("editedChapterCount:", editedChapterCount);
//   console.log("editedSubject:", editedSubject);

//   const handleEditClick = () => {
//     setEditMode(true);
//     // Initialize editedGrade with data.grade when entering edit mode
//     setEditedGrade(data.grade);
//     setEditedChapterCount(data.chapterCount);
//     setEditedSubject(data.subject);
//   };

//   // const handleSaveChanges = () => {
//   //   setEditMode(false);
//   // };
//   const handleSaveChanges = async () => {
//     try {
//       // Prepare the updated data
//       const updatedData = {
//         subject: editedSubject,
//         grade: editedGrade,
//         chapterCount: editedChapterCount
//         // Add other fields as needed
//       };

//       // Send a PUT request to update the data
//       const response = await axios.put(`https://api.studentrays.com/api/datas/${data._id}`, updatedData);

//       // Handle the response as needed
//       console.log("Updated data:", response.data);

//       // Perform any other actions needed after successful update
//       setEditMode(false);
//       window.location.reload();
//     } catch (error) {
//       console.error("Error updating data:", error);
//       // Handle errors as needed
//     }
//   };

//   return (
//     <div className="single">
//       <Sidebar />
//       <div className="singleContainer">
//         <Navbar />
//         <div className="top">
//           <div className="left">
//             <div className="editButton" onClick={handleEditClick}>
//               Edit
//             </div>
//             <div className="item">
//               <Link to={`/datas/newdetail/${data._id}`} className="link">
//                 Add New
//               </Link>
//               <div className={`details ${editMode ? "editMode" : ""}`}>
//                 {editMode ? (
//                   <input
//                     className="editInput"
//                     type="text"
//                     value={editedSubject}
//                     onChange={(e) => setEditedSubject(e.target.value)}
//                   />
//                 ) : (
//                   <h1 className="itemTitle">{data.subject}</h1>
//                 )}
//                 <div className="detailItem">
//                   <span className="itemKey">Grade:</span>
//                   {editMode ? (
//                     <input
//                       className="editInput"
//                       type="text"
//                       value={editedGrade}
//                       onChange={(e) => setEditedGrade(e.target.value)}
//                     />
//                   ) : (
//                     <span className="itemValue">{data.grade}</span>
//                   )}
//                 </div>
//                 <div className="detailItem">
//                   <span className="itemKey">ID:</span>
//                   <span className="itemValue">{data._id}</span>
//                 </div>
//                 <div className="detailItem">
//                   <span className="itemKey">ChapterCount:</span>
//                   {editMode ? (
//                     <input
//                       className="editInput"
//                       type="text"
//                       value={editedChapterCount}
//                       onChange={(e) => setEditedChapterCount(e.target.value)}
//                     />
//                   ) : (
//                     <span className="itemValue">{data.chapterCount}</span>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//           {editMode && (
//             <button onClick={handleSaveChanges}>Save Changes</button>
//           )}











//           <TableContainer component={Paper} className="table">
//             <Table sx={{ minWidth: 350 }} aria-label="simple table">
//               <TableHead>
//                 <TableRow>
//                   <TableCell className="tableCell">Chapter Name</TableCell>
//                   <TableCell className="tableCell">ID</TableCell>
//                   <TableCell className="tableCell">view</TableCell>
//                   <TableCell className="tableCell">Delete</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {detail &&
//                 detail.map((oneData) => (
//                   <Chapterdetail key={oneData} chid={data._id} id={oneData} />
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>

//           {/* <div className="detailItem">
//             <span className="itemKey">Chapterdetails:</span>
//             <span className="itemValue">
//               {detail &&
//                 detail.map((oneData) => (
//                   <Chapterdetail key={oneData} chid={data._id} id={oneData} />
//                 ))}
//             </span>
//           </div> */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Single;
import React, { useState, useCallback, useEffect } from "react";
import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import useFetch from "../../hooks/useFetch";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Chapterdetail from "./Chapterdetail";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const Single = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const id = location.pathname.split("/")[2];
  const { data, loading, error, reFetch } = useFetch(`https://api.studentrays.com/api/datas/find/${id}`);
  
  const [editMode, setEditMode] = useState(false);
  const [editedGrade, setEditedGrade] = useState("");
  const [editedChapterCount, setEditedChapterCount] = useState("");
  const [editedSubject, setEditedSubject] = useState("");
  const [chapterDetails, setChapterDetails] = useState([]);

  useEffect(() => {
    if (data && data.chapterdetails) {
      setChapterDetails(data.chapterdetails);
    }
  }, [data]);

  const handleEditClick = () => {
    setEditMode(true);
    setEditedGrade(data.grade);
    setEditedChapterCount(data.chapterCount);
    setEditedSubject(data.subject);
  };

  const handleSaveChanges = async () => {
    try {
      const updatedData = {
        subject: editedSubject,
        grade: editedGrade,
        chapterCount: editedChapterCount
      };

      const response = await axios.put(`https://api.studentrays.com/api/datas/${data._id}`, updatedData);
      console.log("Updated data:", response.data);
      setEditMode(false);
      reFetch();
    } catch (error) {
      console.error("Error updating data:", error);
      alert("Failed to update data. Please try again.");
    }
  };

  // const handleDeleteClick = async () => {
  //   if (window.confirm("Are you sure you want to delete this item?")) {
  //     try {
  //       await axios.delete(`https://api.studentrays.com/api/datas/${data._id}`);
  //       console.log("Item deleted successfully");
  //       navigate('/datas');
  //     } catch (error) {
  //       console.error("Error deleting data:", error);
  //       alert("Failed to delete item. Please try again.");
  //     }
  //   }
  // };

  // const handleChapterDelete = useCallback(async (chapterId) => {
  //   if (window.confirm("Are you sure you want to delete this chapter?1")) {
  //     try {
  //       await axios.delete(`https://api.studentrays.com/api/chapterdetails/${chapterId}`);
  //       setChapterDetails(prevDetails => prevDetails.filter(detail => detail !== chapterId));
  //       console.log("Chapter deleted successfully");
  //     } catch (error) {
  //       console.error("Error deleting chapter:", error);
  //       // alert("Failed to delete chapter. Please try again.");
  //     }
  //   }
  // }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButton" onClick={handleEditClick}>
              Edit
            </div>
            {/* <div className="deleteButton" onClick={handleDeleteClick}>
              Delete
            </div> */}
            <div className="item">
              <Link to={`/datas/newdetail/${data._id}`} className="link">
                Add New
              </Link>
              <div className={`details ${editMode ? "editMode" : ""}`}>
                {editMode ? (
                  <input
                    className="editInput"
                    type="text"
                    value={editedSubject}
                    onChange={(e) => setEditedSubject(e.target.value)}
                  />
                ) : (
                  <h1 className="itemTitle">{data.subject}</h1>
                )}
                <div className="detailItem">
                  <span className="itemKey">Grade:</span>
                  {editMode ? (
                    <input
                      className="editInput"
                      type="text"
                      value={editedGrade}
                      onChange={(e) => setEditedGrade(e.target.value)}
                    />
                  ) : (
                    <span className="itemValue">{data.grade}</span>
                  )}
                </div>
                <div className="detailItem">
                  <span className="itemKey">ID:</span>
                  <span className="itemValue">{data._id}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">ChapterCount:</span>
                  {editMode ? (
                    <input
                      className="editInput"
                      type="text"
                      value={editedChapterCount}
                      onChange={(e) => setEditedChapterCount(e.target.value)}
                    />
                  ) : (
                    <span className="itemValue">{data.chapterCount}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          {editMode && (
            <button onClick={handleSaveChanges}>Save Changes</button>
          )}

          <TableContainer component={Paper} className="table">
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableCell">Chapter Name</TableCell>
                  <TableCell className="tableCell">ID</TableCell>
                  <TableCell className="tableCell">view</TableCell>
                  <TableCell className="tableCell">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {chapterDetails.map((oneData) => (
                  <Chapterdetail 
                    key={oneData} 
                    chid={data._id} 
                    id={oneData} 
                    // onDelete={() => handleChapterDelete(oneData)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default Single;