import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import TcList from "./pages/list/TcList";
import Single from "./pages/single/Single";
import Assign from "./pages/single/Assign";
import SingleTeacher from "./pages/single/SingleTeacher";
import SingleData from "./pages/single/SingleData";
import SingleDetail from "./pages/single/SingleDetail";
import AllTopic from "./pages/single/AllTopic";
import FinalTopic from "./pages/single/FinalTopic";
import New from "./pages/new/New";
import NewData from "./pages/newData/New";
import NewDetail from "./pages/newDetail/New";
import NewSession from "./pages/newSession/New";
import NewSubTopic from "./pages/newSubTopic/New";
import NewAllQuestion from "./pages/newAllQuestion/New";
import NewQuestion from "./pages/newQuestion/New";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  productInputs,
  userInputs,
  dataInputs,
  chapdetailInputs,
  subtopicInputs,
  sessionInputs,
  allquestionInputs,
} from "./formSource";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/AuthContext";
import {
  dataColumns,
  roomColumns,
  userColumns,
  studentColumns,
  teacherColumns,
  counsellorColumns,
  assignTeacherColumns,
} from "./datatablesource";
// import NewHotel from "./pages/newHotel/NewHotel";
import Detail from "./pages/detail/Detail";
import AllDetail from "./pages/allDetail/AllDetail";
import AllteacherDetail from "./pages/allDetail/AllteacherDetail";
import AllcounsellorDetail from "./pages/allDetail/AllcounsellorDetail";
import Assignteacher from "./pages/assignteacher/Assignteacher";
import Batch from "./pages/batch/Batch";
import NewRoom from "./pages/newRoom/NewRoom";

function App() {
  const { darkMode } = useContext(DarkModeContext);

  const ProtectedRoute = ({ children }) => {
    const { user } = useContext(AuthContext);

    if (!user) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="login" element={<Login />} />
            <Route
              index
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route path="users">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <List columns={userColumns} />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":userId"
                element={
                  <ProtectedRoute>
                    <Single />
                  </ProtectedRoute>
                }
              />
              <Route
                path="assign/:userId"
                element={
                  <ProtectedRoute>
                    <Assign />
                  </ProtectedRoute>
                }
              />
              <Route
                path="new"
                element={
                  <ProtectedRoute>
                    <New inputs={userInputs} title="Add New User" />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="datas">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <List columns={dataColumns} />
                    {/* <List columns={hotelColumns} /> */}
                  </ProtectedRoute>
                }
              />
              <Route
                path=":productId"
                element={
                  <ProtectedRoute>
                    <SingleData />
                  </ProtectedRoute>
                }
              />
              <Route
                path="chapter/:chid/:detailId"
                element={
                  <ProtectedRoute>
                    <SingleDetail />
                  </ProtectedRoute>
                }
              />
              <Route
                path="chapter/topic/:chid/:id"
                element={
                  <ProtectedRoute>
                    <AllTopic />
                  </ProtectedRoute>
                }
              />
              <Route
                path="chapter/subtopic/:chid/:id"
                element={
                  <ProtectedRoute>
                    <FinalTopic />
                  </ProtectedRoute>
                }
              />
              <Route
                path="new"
                element={
                  <ProtectedRoute>
                    <NewData inputs={dataInputs} title="Add New Data" />
                  </ProtectedRoute>
                }
              />
              <Route
                path="newdetail/:id"
                element={
                  <ProtectedRoute>
                    <NewDetail
                      inputs={chapdetailInputs}
                      title="Add New Detail"
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="newsubtopic/:id"
                element={
                  <ProtectedRoute>
                    <NewSubTopic
                      inputs={subtopicInputs}
                      title="Add New SubTopic"
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="newsession/:id"
                element={
                  <ProtectedRoute>
                    <NewSession
                      inputs={sessionInputs}
                      title="Add New Session"
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="newallquestion/:id"
                element={
                  <ProtectedRoute>
                    <NewAllQuestion
                      inputs={allquestionInputs}
                      title="Add New All Question"
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="newQuestion/:id"
                element={
                  <ProtectedRoute>
                    <NewQuestion
                      inputs={allquestionInputs}
                      title="Add New Question"
                    />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="users/teachers">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <TcList columns={teacherColumns} />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":id"
                element={
                  <ProtectedRoute>
                    <SingleTeacher />
                  </ProtectedRoute>
                }
              />
              {/* <Route
                path=":productId"
                element={
                  <ProtectedRoute>
                    <Single />
                  </ProtectedRoute>
                }
              />
              <Route
                path="new"
                element={
                  <ProtectedRoute>
                    <NewRoom />
                  </ProtectedRoute>
                }
              /> */}
            </Route>
            <Route path="users/counsellors">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <TcList columns={counsellorColumns} />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="users/assign-teacher">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <Assignteacher columns={assignTeacherColumns} />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="users/batch">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <Batch columns={assignTeacherColumns} />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="users/students">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <TcList columns={studentColumns} />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="students">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <Detail />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":grade/:section"
                element={
                  <ProtectedRoute>
                    {/* <TcList columns={counsellorColumns} /> */}
                    <AllDetail columns={userColumns} />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="teachers">
              <Route
                path=":grade/:section"
                element={
                  <ProtectedRoute>
                    <AllteacherDetail columns={userColumns} />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="counsellors">
              <Route
                path=":grade/:section"
                element={
                  <ProtectedRoute>
                    {/* <TcList columns={counsellorColumns} /> */}
                    <AllcounsellorDetail columns={userColumns} />
                  </ProtectedRoute>
                }
              />
            </Route>
            {/* <Route path="rooms">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <List columns={roomColumns} />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":productId"
                element={
                  <ProtectedRoute>
                    <Single />
                  </ProtectedRoute>
                }
              />
              <Route
                path="new"
                element={
                  <ProtectedRoute>
                    <NewRoom />
                  </ProtectedRoute>
                }
              />
            </Route> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
