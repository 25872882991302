import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import axios from "axios";

const Datatabledetail = (props) => {
  const location = useLocation();
  const path = "users";
  const [list, setList] = useState([]);  // Initialize as an empty array
  const { data, loading, error } = useFetch(`https://api.studentrays.com/api/${path}`);
  console.log("Datatable Data", data);
  console.log("allfilteredDataindetail1", props.allfilteredData);
  const columns = props.columns;

  useEffect(() => {
    if (data) {  // Only set the list if data is available
      setList(data);
    }
  }, [data]);

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        await axios.delete(`https://api.studentrays.com/api/${path}/${id}`);
        setList(list.filter((item) => item._id !== id));
      } catch (err) {
        console.error("Error deleting item:", err);
      }
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {props.person === "teacher" ? (
              <Link
                to={{
                  pathname: `/${path}/teachers/${params.row._id}`,
                  props: { nowid: params.row._id },
                }}
                style={{ textDecoration: "none" }}
              >
                <div className="viewButton">View</div>
              </Link>
            ) : (
              <Link
                to={`/${path}/${params.row._id}`}
                style={{ textDecoration: "none" }}
              >
                <div className="viewButton">View</div>
              </Link>
            )}
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row._id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  // Don't render the DataGrid until data is loaded
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="datatable">
      <div className="datatableTitle">
        {path}
        <Link to={`/${path}/new`} className="link">
          Add New
        </Link>
      </div>
      {props.allfilteredData && props.allfilteredData.length > 0 ? (
        <DataGrid
          className="datagrid"
          rows={props.allfilteredData}
          columns={columns.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}
          checkboxSelection
          getRowId={(row) => row._id}
        />
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

export default Datatabledetail;