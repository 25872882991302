export const userInputs = [
  {
    id: "username",
    label: "Username",
    type: "text",
    placeholder: "Account_user_name",
  },
  {
    id: "name",
    label: "Name",
    type: "text",
    placeholder: "Enter youe Nmae",
  },
  {
    id: "email",
    label: "Email",
    type: "email",
    placeholder: "your_email@gmail.com",
  },
  {
    id: "phone",
    label: "Phone",
    type: "text",
    placeholder: "+1 234 567 89",
  },
  {
    id: "password",
    label: "Password",
    type: "password",
  },
  {
    id: "state",
    label: "State",
    type: "text",
    placeholder: "Your State",
  },
  {
    id: "grade",
    label: "Grade",
    type: "text",
    placeholder: "Enter Grade",
  },
  {
    id: "course",
    label: "Course",
    type: "text",
    placeholder: "Enter course",
  },
  {
    id: "section",
    label: "Section",
    type: "text",
    placeholder: "Enter your Section",
  },
];
export const dataInputs = [
  {
    id: "grade",
    label: "Grade",
    type: "number",
    placeholder: "Your_Grade",
  },
  {
    id: "subject",
    label: "Subject",
    type: "text",
    placeholder: "your_Subject",
  },
  {
    id: "chapterCount",
    label: "ChapterCount",
    type: "number",
    placeholder: "Your_ChapterCount",
  },
];
export const chapdetailInputs = [
  {
    id: "chapterName",
    label: "ChapterName",
    type: "text",
    placeholder: "Your_chapterName",
  },
];
export const subtopicInputs = [
  {
    id: "SubTopic",
    label: "SubTopicName",
    type: "text",
    placeholder: "Your_subtopicName",
  },
];
export const sessionInputs = [
  {
    id: "topic",
    label: "SessionName",
    type: "text",
    placeholder: "Your_sessionName",
    required: true,
  },
];
export const allquestionInputs = [
  {
    id: "question",
    label: "Question",
    type: "text",
    placeholder: "Your_question",
  },
  {
    id: "correctAnswer",
    label: "Correct Answer",
    type: "text",
    placeholder: "Your_correctAnswer",
  },
];

export const productInputs = [
  {
    id: 1,
    label: "Title",
    type: "text",
    placeholder: "Apple Macbook Pro",
  },
  {
    id: 2,
    label: "Description",
    type: "text",
    placeholder: "Description",
  },
  {
    id: 3,
    label: "Category",
    type: "text",
    placeholder: "Computers",
  },
  {
    id: 4,
    label: "Price",
    type: "text",
    placeholder: "100",
  },
  {
    id: 5,
    label: "Stock",
    type: "text",
    placeholder: "in stock",
  },
];

export const hotelInputs = [
  {
    id: "name",
    label: "Name",
    type: "text",
    placeholder: "My Hotel",
  },
  {
    id: "type",
    label: "Type",
    type: "text",
    placeholder: "hotel",
  },
  {
    id: "city",
    label: "City",
    type: "text",
    placeholder: "New York",
  },
  {
    id: "address",
    label: "Address",
    type: "text",
    placeholder: "elton st, 216",
  },
  {
    id: "distance",
    label: "Distance from City Center",
    type: "text",
    placeholder: "500",
  },
  {
    id: "title",
    label: "Title",
    type: "text",
    placeholder: "The best Hotel",
  },
  {
    id: "desc",
    label: "Description",
    type: "text",
    placeholder: "description",
  },
  {
    id: "cheapestPrice",
    label: "Price",
    type: "text",
    placeholder: "100",
  },
];

export const roomInputs = [
  {
    id: "title",
    label: "Title",
    type: "text",
    placeholder: "2 bed room",
  },
  {
    id: "desc",
    label: "Description",
    type: "text",
    placeholder: "King size bed, 1 bathroom",
  },
  {
    id: "price",
    label: "Price",
    type: "number",
    placeholder: "100",
  },
  {
    id: "maxPeople",
    label: "Max People",
    type: "number",
    placeholder: "2",
  },
];
