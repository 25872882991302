
// import "./single.scss";
// import React, { useState } from "react";
// import axios from "axios";

// const Finalquestion = (props) => {
//   const [data, setData] = useState(props.data);
//   const [finalquestions, setFinalQuestions] = useState(data.questions || []);
//   const questionsLength = finalquestions.length;

//   const [qEditIndex, setqEditIndex] = useState(-1);
//   const [editedQuestion, setEditedQuestion] = useState("");
//   const [editedOptions, setEditedOptions] = useState([]);
//   const [editedCorrectAnswer, setEditedCorrectAnswer] = useState("");
//   console.log("data", data);
//   console.log("finalquestions", finalquestions);
//   const handleqEditClick = (i) => {
//     setqEditIndex(i);
//     setEditedQuestion(finalquestions[i].question);
//     setEditedOptions([...finalquestions[i].options]);
//     setEditedCorrectAnswer(finalquestions[i].correctAnswer);
//   };

//   const handleSaveClick = async (qindex, topicId) => {
//     try {
//       const updatedData = {
//         question: editedQuestion,
//         options: editedOptions,
//         correctAnswer: editedCorrectAnswer,
//       };
//       console.log("updatedData", updatedData);
//       const response = await axios.put(
//         `https://api.studentrays.com/api/questions/${topicId}/${data._id}`,
//         updatedData
//       );

//       console.log("Server response:", response.data);

//       // Update local state
//       const updatedQuestions = [...finalquestions];
//       updatedQuestions[qindex] = {
//         ...updatedQuestions[qindex],
//         ...updatedData,
//       };
//       setFinalQuestions(updatedQuestions);

//       setqEditIndex(-1);
//       setEditedQuestion("");
//       setEditedOptions([]);
//       setEditedCorrectAnswer("");
//     } catch (error) {
//       console.error("Error saving question:", error);
//     }
//   };

//   const handleDeleteClick = async (qindex, topicId) => {
//     const confirmDelete = window.confirm(
//       "Are you sure you want to delete this question?"
//     );
//     if (confirmDelete) {
//       try {
//         const response = await axios.delete(
//           `https://api.studentrays.com/api/questions/${topicId}/${data._id}`
//         );
//         console.log("Server response:", response.data);

//         // Update local state
//         const updatedQuestions = finalquestions.filter(
//           (_, index) => index !== qindex
//         );
//         setFinalQuestions(updatedQuestions);
//       } catch (error) {
//         console.error("Error deleting question:", error);
//       }
//     }
//   };

//   return (
//     <div className="topicquestions ">
//       <span className="bold">Total questions: {questionsLength}</span>

//       {finalquestions.map((onetopic, qindex) => (
//         <div className="questioncontainer" key={qindex}>
//           <div className="question">
//             <span className="bold">question:</span>
//             {qEditIndex === qindex ? (
//               <input
//                 className="editInput"
//                 type="text"
//                 value={editedQuestion}
//                 onChange={(e) => setEditedQuestion(e.target.value)}
//               />
//             ) : (
//               <span>{onetopic && onetopic.question}</span>
//             )}
//           </div>
//           <div className="options">
//             {onetopic &&
//               onetopic.options &&
//               Array.isArray(onetopic.options) &&
//               onetopic.options.map((option, optionIndex) => (
//                 <div key={optionIndex}>
//                   <span className="bold">.{optionIndex + 1}</span>
//                   {qEditIndex === qindex ? (
//                     <input
//                       className="editInput"
//                       type="text"
//                       value={editedOptions[optionIndex]}
//                       onChange={(e) => {
//                         const updatedOptions = [...editedOptions];
//                         updatedOptions[optionIndex] = e.target.value;
//                         setEditedOptions(updatedOptions);
//                       }}
//                     />
//                   ) : (
//                     <span className="option" key={optionIndex}>
//                       {option}
//                     </span>
//                   )}
//                 </div>
//               ))}
//           </div>
//           <div className="question">
//             <span className="bold">correctAnswer:</span>
//             {qEditIndex === qindex ? (
//               <input
//                 className="editInput"
//                 type="text"
//                 value={editedCorrectAnswer}
//                 onChange={(e) => setEditedCorrectAnswer(e.target.value)}
//               />
//             ) : (
//               <span>{onetopic && onetopic.correctAnswer}</span>
//             )}
//           </div>
//           {qEditIndex === qindex ? (
//             <button
//               className="link questionbtn"
//               onClick={() => handleSaveClick(qindex, onetopic._id)}
//             >
//               Save
//             </button>
//           ) : (
//             <div className="flexcontainer">
//               <button
//                 className="link questionbtn"
//                 onClick={() => handleqEditClick(qindex)}
//               >
//                 Edit
//               </button>
//               <button
//                 className="link questionbtn delete-btn"
//                 onClick={() => handleDeleteClick(qindex, onetopic._id)}
//               >
//                 Delete
//               </button>
//             </div>
//           )}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Finalquestion;
import "./single.scss";
import React, { useState, useEffect } from "react";
import axios from "axios";

const Finalquestion = (props) => {
  const [data, setData] = useState(props.data);
  const [finalquestions, setFinalQuestions] = useState([]);
  const [qEditIndex, setqEditIndex] = useState(-1);
  const [editedQuestion, setEditedQuestion] = useState("");
  const [editedOptions, setEditedOptions] = useState([]);
  const [editedCorrectAnswer, setEditedCorrectAnswer] = useState("");

  useEffect(() => {
    console.log("props.data changed:", props.data);
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    console.log("data changed:", data);
    if (data && data.questions) {
      setFinalQuestions(data.questions);
    }
  }, [data]);
  const questionsLength = finalquestions.length;

  console.log("data", data);
  console.log("finalquestions", finalquestions);

  const handleqEditClick = (i) => {
    setqEditIndex(i);
    setEditedQuestion(finalquestions[i].question);
    setEditedOptions([...finalquestions[i].options]);
    setEditedCorrectAnswer(finalquestions[i].correctAnswer);
  };

  const handleSaveClick = async (qindex) => {
    try {
      const updatedData = {
        question: editedQuestion,
        options: editedOptions,
        correctAnswer: editedCorrectAnswer,
      };
      console.log("updatedData", updatedData);
      const response = await axios.put(
        `https://api.studentrays.com/api/questions/${data._id}/${finalquestions[qindex]._id}`,
        updatedData
      );

      console.log("Server response:", response.data);

      // Update local state
      const updatedQuestions = [...finalquestions];
      updatedQuestions[qindex] = {
        ...updatedQuestions[qindex],
        ...updatedData,
      };
      setFinalQuestions(updatedQuestions);
      setData({ ...data, questions: updatedQuestions });

      setqEditIndex(-1);
      setEditedQuestion("");
      setEditedOptions([]);
      setEditedCorrectAnswer("");
    } catch (error) {
      console.error("Error saving question:", error);
    }
  };

  const handleDeleteClick = async (qindex) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this question?"
    );
    if (confirmDelete) {
      try {
        const response = await axios.delete(
          `https://api.studentrays.com/api/questions/${data._id}/${finalquestions[qindex]._id}`
        );
        console.log("Server response:", response.data);

        // Update local state
        const updatedQuestions = finalquestions.filter(
          (_, index) => index !== qindex
        );
        setFinalQuestions(updatedQuestions);
        setData({ ...data, questions: updatedQuestions });
      } catch (error) {
        console.error("Error deleting question:", error);
      }
    }
  };

  return (
    <div className="topicquestions ">
      <span className="bold">Total questions: {questionsLength}</span>

      {finalquestions.map((onetopic, qindex) => (
        <div className="questioncontainer" key={qindex}>
          <div className="question">
            <span className="bold">question:</span>
            {qEditIndex === qindex ? (
              <input
                className="editInput"
                type="text"
                value={editedQuestion}
                onChange={(e) => setEditedQuestion(e.target.value)}
              />
            ) : (
              <span>{onetopic && onetopic.question}</span>
            )}
          </div>
          <div className="options">
            {onetopic &&
              onetopic.options &&
              Array.isArray(onetopic.options) &&
              onetopic.options.map((option, optionIndex) => (
                <div key={optionIndex}>
                  <span className="bold">.{optionIndex + 1}</span>
                  {qEditIndex === qindex ? (
                    <input
                      className="editInput"
                      type="text"
                      value={editedOptions[optionIndex]}
                      onChange={(e) => {
                        const updatedOptions = [...editedOptions];
                        updatedOptions[optionIndex] = e.target.value;
                        setEditedOptions(updatedOptions);
                      }}
                    />
                  ) : (
                    <span className="option" key={optionIndex}>
                      {option}
                    </span>
                  )}
                </div>
              ))}
          </div>
          <div className="question">
            <span className="bold">correctAnswer:</span>
            {qEditIndex === qindex ? (
              <input
                className="editInput"
                type="text"
                value={editedCorrectAnswer}
                onChange={(e) => setEditedCorrectAnswer(e.target.value)}
              />
            ) : (
              <span>{onetopic && onetopic.correctAnswer}</span>
            )}
          </div>
          {qEditIndex === qindex ? (
            <button
              className="link questionbtn"
              onClick={() => handleSaveClick(qindex)}
            >
              Save
            </button>
          ) : (
            <div className="flexcontainer">
              <button
                className="link questionbtn"
                onClick={() => handleqEditClick(qindex)}
              >
                Edit
              </button>
              <button
                className="link questionbtn delete-btn"
                onClick={() => handleDeleteClick(qindex)}
              >
                Delete
              </button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Finalquestion;