// import React, { useContext, useState } from "react";
// import "./navbar.scss";
// import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
// import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
// import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
// import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
// import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
// import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
// import { DarkModeContext } from "../../context/darkModeContext";

// const Navbar = () => {
//   const { dispatch } = useContext(DarkModeContext);
//   const [selectedPersonValue, setSelectedPersonValue] = useState("");
//   const [selectedSectionValue, setSelectedSectionValue] = useState("");
//   const [selectedValue, setSelectedValue] = useState("");

//   const handleSelectChangePerson = (event) => {
//     setSelectedPersonValue(event.target.value);
//   };

//   const handleSelectChangesection = (event) => {
//     setSelectedSectionValue(event.target.value);
//   };

//   const handleSelectChange = (event) => {
//     setSelectedValue(event.target.value);
//   };

//   const handleSubmit = () => {
//     if (selectedPersonValue && selectedSectionValue && selectedValue) {
//       const baseUrl = window.location.origin;
//       const urlToNavigate = `${baseUrl}/${selectedPersonValue.toLowerCase()}s/${selectedSectionValue}/${selectedValue}`;
//       window.location.href = urlToNavigate;
//     } else {
//       alert("Please select all options before submitting.");
//     }
//   };

//   return (
//     <div className="navbar">
//       <div className="wrapper">
//         {/* <div className="search">
//           <input type="text" placeholder="Search..." />
//           <SearchOutlinedIcon />
//         </div> */}

//         <div>
//           <label>
//             Person:
//             <select
//               name="person"
//               onChange={handleSelectChangePerson}
//               value={selectedPersonValue}
//             >
//               <option value="">None</option>
//               <option value="Student">Student</option>
//               <option value="Teacher">Teacher</option>
//               <option value="Counsellor">Counsellor</option>
//             </select>
//           </label>
//           <label>
//             Class:
//             <select
//               name="detail"
//               onChange={handleSelectChangesection}
//               value={selectedSectionValue}
//             >
//               <option value="">None</option>
//               <option value="6">6th std</option>
//               <option value="7">7th std</option>
//               <option value="8">8th std</option>
//               <option value="9">9th std</option>
//               <option value="10">10th std</option>
//               <option value="11">11th std</option>
//               <option value="12">12th std</option>
//             </select>
//           </label>
//           <label>
//             Section:
//             <select
//               name="section"
//               onChange={handleSelectChange}
//               value={selectedValue}
//             >
//               <option value="">None</option>
//               <option value="A">Section A</option>
//               <option value="B">Section B</option>
//               <option value="C">Section C</option>
//             </select>
//           </label>
//           <button type="button" onClick={handleSubmit}>
//             Go
//           </button>
//         </div>

//         <div className="items">
//           <div className="item">
//             <LanguageOutlinedIcon className="icon" />
//             English
//           </div>
//           <div className="item">
//             <DarkModeOutlinedIcon
//               className="icon"
//               onClick={() => dispatch({ type: "TOGGLE" })}
//             />
//           </div>
//           {/* <div className="item">
//             <FullscreenExitOutlinedIcon className="icon" />
//           </div>
//           <div className="item">
//             <NotificationsNoneOutlinedIcon className="icon" />
//             <div className="counter">1</div>
//           </div>
//           <div className="item">
//             <ChatBubbleOutlineOutlinedIcon className="icon" />
//             <div className="counter">2</div>
//           </div>
//           <div className="item">
//             <ListOutlinedIcon className="icon" />
//           </div>
//           <div className="item">
//             <img
//               src="https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
//               alt=""
//               className="avatar"
//             />
//           </div> */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Navbar;
import React, { useContext, useState } from "react";
import "./navbar.scss";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import { DarkModeContext } from "../../context/darkModeContext";

const Navbar = () => {
  const { dispatch } = useContext(DarkModeContext);
  const [selectedPerson, setSelectedPerson] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");

  const handlePersonChange = (event) => {
    setSelectedPerson(event.target.value);
  };

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
  };

  const handleSectionChange = (event) => {
    setSelectedSection(event.target.value);
  };

  const handleSubmit = () => {
    if (selectedPerson && selectedClass && selectedSection) {
      const baseUrl = window.location.origin;
      const urlToNavigate = `${baseUrl}/${selectedPerson.toLowerCase()}s/${selectedClass}/${selectedSection}`;
      window.location.href = urlToNavigate;
    } else {
      alert("Please select all options before submitting.");
    }
  };

  return (
    <div className="navbar">
      <div className="wrapper">
        {/* <div className="search">
          <input type="text" placeholder="Search..." />
          <SearchOutlinedIcon />
        </div> */}

        <div className="selection">
          <label>
            Person:
            <select
              name="person"
              onChange={handlePersonChange}
              value={selectedPerson}
            >
              <option value="">None</option>
              <option value="Student">Student</option>
              <option value="Teacher">Teacher</option>
              <option value="Counsellor">Counsellor</option>
            </select>
          </label>
          <label>
            Class:
            <select
              name="class"
              onChange={handleClassChange}
              value={selectedClass}
            >
              <option value="">None</option>
              <option value="6">6th std</option>
              <option value="7">7th std</option>
              <option value="8">8th std</option>
              <option value="9">9th std</option>
              <option value="10">10th std</option>
              <option value="11">11th std</option>
              <option value="12">12th std</option>
            </select>
          </label>
          <label>
            Section:
            <select
              name="section"
              onChange={handleSectionChange}
              value={selectedSection}
            >
              <option value="">None</option>
              <option value="A">Section A</option>
              <option value="B">Section B</option>
              <option value="C">Section C</option>
            </select>
          </label>
          <button type="button" onClick={handleSubmit}>
            Go
          </button>
        </div>

        <div className="items">
          <div className="item">
            <LanguageOutlinedIcon className="icon" />
            English
          </div>
          <div className="item">
            <DarkModeOutlinedIcon
              className="icon"
              onClick={() => dispatch({ type: "TOGGLE" })}
            />
          </div>
          {/* Uncomment these as needed */}
          {/* <div className="item">
            <FullscreenExitOutlinedIcon className="icon" />
          </div>
          <div className="item">
            <NotificationsNoneOutlinedIcon className="icon" />
            <div className="counter">1</div>
          </div>
          <div className="item">
            <ChatBubbleOutlineOutlinedIcon className="icon" />
            <div className="counter">2</div>
          </div>
          <div className="item">
            <ListOutlinedIcon className="icon" />
          </div>
          <div className="item">
            <img
              src="https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
              alt="User Avatar"
              className="avatar"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
