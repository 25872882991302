import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns, userRows } from "../../datatablesource";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import axios from "axios";

const AssignteacherDatatable = ({ columns }) => {
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  console.log("pathpath", path);
  // const paths = location.pathname.split("/")[2];
  const [list, setList] = useState();
  const [teacherList, setTeacherList] = useState([]);
  const [getauthorizationUrl, setGetauthorizationUrl] = useState();
  const { data, loading, error } = useFetch("https://api.studentrays.com/api/users/assign/teacher");
  console.log("AssignteacherDatatable Data", data);

  useEffect(() => {
    setList(data);
  }, [data]);

  const handleAssign = async (grade) => {
    try {
      const path = grade;
      console.log("grade", grade);
      // const users = localStorage.getItem("user");
      //   const user =  JSON.parse(users);
      //   const token = user.token;
      //   const config = {
      //     headers: { Authorization: `Bearer ${token}` },
      //   };
      const teacherdata = await axios.get(`https://api.studentrays.com/api/users/allteacher/${path}`);
      console.log("teacherdata", teacherdata);
      //   if teacherdata contains (teacher.grade which are 5,6,7,8,9,10  than remove that data
      let data = teacherdata.data.map((teacher) => {
        // get the username and teacherSection of each teacher
        let username = teacher.username;
        let teacherSection = teacher.teacherSection;
        // convert the teacherSection array into a string
        let teacherSectionString = JSON.stringify(teacherSection);
        // console.log("username", username, "teacherSection", teacherSection);
        // return a string with the username and teacherSection
        return {
          label: `${username} ${teacherSectionString}`,
          value: teacher._id,
        };
      });
      // console.log("data", data);
      setTeacherList(data);
      let dataString = data.join("\n");
      // console.log("dataString", dataString);
      // alert(dataString);
      

      //   setList(list.filter((item) => item._id !== id));
    } catch (err) {}
  };
  const handleSelectChange = async (rowId, selectedValue) => {
    try {
      console.log("Row ID:", rowId);
      console.log("Selected value:", selectedValue);
      const eventResponse = await axios.get(`https://api.studentrays.com/api/events/teacher/${selectedValue}`);
      console.log("eventResponse", eventResponse);
      let studentidArray;
      let response;
      if (eventResponse.data.length > 0) {
        studentidArray = [...eventResponse.data[0].studentid, rowId];
        const datatosend = {
          teacherid: selectedValue,
          studentid: studentidArray,
          meetinglink: "https://meet.google.com/amc-ekhz-yxp",
        };
        console.log("eventResponse2", eventResponse);
        response = await axios.put(
          `/events/${eventResponse.data[0]._id}`,
          datatosend
        );
        console.log("response", response);
      } else {
        const newdatatosend = {
          teacherid: selectedValue,
          studentid: [rowId],
          meetinglink: "https://meet.google.com/amc-ekhz-yxp",
        };
        response = await axios.post(`https://api.studentrays.com/api/events/`, newdatatosend);
        console.log("response", response);
        studentidArray = [rowId];
      }
      const datatosendtouser = {
        teacherAssigned: response.data._id,
      };
      console.log("datatosendtouser", datatosendtouser);
      const responsedata = await axios.put(`https://api.studentrays.com/api/users/${rowId}`, datatosendtouser);
      console.log("responsedata", responsedata);
    } catch (err) {
      console.error(err);
    }
  };

  const createMeeting = async () => {
    try {
      const response = await axios.get(`https://api.studentrays.com/api/auth/zoom`);
      console.log(response.data.authorizationUrl, 12);
      // setGetauthorizationUrl(response.data.authorizationUrl);
      // console.log("getUrl", getauthorizationUrl);
      window.location.href = response.data.authorizationUrl;
    } catch (error) {
      console.error("Error initiating Zoom OAuth:", error);
    }
  };

  const getCodeFromUrl = () => {
    // const searchParams = new URLSearchParams(url);
    const searchParams = new URLSearchParams(window.location.search);
    console.log(searchParams, 21);
    const code = searchParams.get("code");
    console.log(code, 23);
    setGetauthorizationUrl(code);
    if (code) {
      // setGetauthorizationUrl(code);
      return code;
    }
  };
  const handleAuth = () => {
    try {
      createMeeting();
      // createMeeting().then(() => {
      //   getCodeFromUrl();
      //   console.log("getauthorizationUrl",getauthorizationUrl);
      // });
    } catch (error) {
      console.log(error);
    }
  };
  const handlecode = () => {
    try {
      // createMeeting()
      // createMeeting().then(() => {
      getCodeFromUrl();
      console.log("getauthorizationUrl", getauthorizationUrl);
      // });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handlecode();
  }, []);
  useEffect(() => {
    console.log("getauthorizationUrl", getauthorizationUrl);
  }, [getauthorizationUrl]); // This will run whenever getauthorizationUrl changes
  
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 500,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={
                `/${path}/assign/${params.row._id}`
              }
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Assign
        {/* <button onClick={() => handleAuth()}>Auth</button>
        <button onClick={() => handlecode()}>Code</button> */}
        {/* <Link to={`/${path}/new`} className="link">
          Add New
        </Link> */}
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        // rows={list}
        columns={columns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
        getRowId={(row) => row._id}
      />
    </div>
  );
};

export default AssignteacherDatatable;
