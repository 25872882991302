import "./single.scss";
import React, { useState, useEffect} from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import useFetch from "../../hooks/useFetch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";

const SingleTeacher = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  console.log("id", id);
  const [allbatch, setAllbatch] = useState([]);
  const { data, loading, error } = useFetch(`https://api.studentrays.com/api/users/${id}`);
  console.log("seeData", data);



  const handleCreateTeacher = async () => {
    console.log("made him teacher");
    // setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    const credentials = {
      isStudent: "false",
      isCounsellor: "false",
      isAdmin: "false",
      isTeacher: "true",
    };

    try {
      const res = await axios.put(`https://api.studentrays.com/api/users/${data._id}`, credentials); // Send a request to your backend logout endpoint
      if (res.status === 200) {
        console.log("create Teacher Success");
        navigate("/users/");
      } else {
        console.log("create Teacher Failed");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleCreateAdmin = async () => {
    console.log("made him teacher");
    // setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    const credentials = {
      isStudent: "false",
      isCounsellor: "false",
      isAdmin: "true",
      isTeacher: "false",
    };

    try {
      const res = await axios.put(`https://api.studentrays.com/api/users/${data._id}`, credentials); // Send a request to your backend logout endpoint
      if (res.status === 200) {
        console.log("create Teacher Success");
        navigate("/users/"); // Send a request to
      } else {
        console.log("create Teacher Failed");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleCreateCounsellor = async () => {
    console.log("made him teacher");
    // setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    const credentials = {
      isStudent: "false",
      isCounsellor: "true",
      isAdmin: "false",
      isTeacher: "false",
    };

    try {
      const res = await axios.put(`https://api.studentrays.com/api/users/${data._id}`, credentials); // Send a request to your backend logout endpoint
      if (res.status === 200) {
        console.log("create Counsellor Success");
        navigate("/users/"); // Send a request to
      } else {
        console.log("create Counsellor Failed");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleCreateStudent = async () => {
    console.log("made him teacher");
    // setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    const credentials = {
      isStudent: "true",
      isCounsellor: "false",
      isAdmin: "false",
      isTeacher: "false",
    };

    try {
      const res = await axios.put(`https://api.studentrays.com/api/users/${data._id}`, credentials); // Send a request to your backend logout endpoint
      if (res.status === 200) {
        console.log("create Student Success");
        navigate("/users/"); // Send a request to
      } else {
        console.log("create Student Failed");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const [checkedValues, setCheckedValues] = useState([]);
  const handleButtonClick = async (event) => {
    console.log(checkedValues);
    event.preventDefault();

    const dataToSend = {
      // name: event.target.name.value,
      // mobileNo: event.target.mobileNo.value,
      // email: event.target.email.value,
      // state: event.target.state.value,
      teacherSection: checkedValues,
    };
    console.log("dataToSend", dataToSend);

    try {
      const response = await axios.put(`https://api.studentrays.com/api/users/${data._id}`, dataToSend);
      console.log("Data sent successfully:", response.data);
      // setformSummit(false);
    } catch (err) {
      console.error("Session failed:", err); // Log the full error object for debugging
      // You can handle the error in a more user-friendly way, such as displaying a message to the user.
    }
  };

  // Function to handle the checkbox change event
  // const handleCheckboxChange = (event) => {
  //   const { name, value, checked } = event.target;

  //   if (checked) {
  //     setCheckedValues((prevCheckedValues) => [...prevCheckedValues, { name, value }]);
  //   } else {
  //     setCheckedValues((prevCheckedValues) =>
  //       prevCheckedValues.filter((item) => item.value !== value)
  //     );
  //   }
  // };

  // const handleCheckboxChange = (event) => {
  //   const { value, checked } = event.target;

  //   if (checked) {
  //     setCheckedValues((prevCheckedValues) => [...prevCheckedValues, value]);
  //   } else {
  //     setCheckedValues((prevCheckedValues) =>
  //       prevCheckedValues.filter((item) => item !== value)
  //     );
  //   }
  // };
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the form from refreshing the page

    const formData = new FormData(event.target);
    const formProps = Object.fromEntries(formData.entries());

    console.log(formProps); // Log the form data
    const batch = `${formProps.course}${formProps.grade}${formProps.subject}${formProps.section}`;
    console.log(batch); // Outputs: "cbsc8bsci"

    // const id = 'your_id_here'; // Replace 'your_id_here' with the actual id

    try {
      // Fetch the current data
      const response = await axios.get(`https://api.studentrays.com/api/users/${id}`);
      const currentBatch = response.data.batch;

      // Check if the batch is already in the currentBatch array
      if (!currentBatch.includes(batch)) {
        // Append the new data to the current data
        const updatedBatch = [...currentBatch, batch];

        // Send the updated data back to the server
        const updateResponse = await axios.put(`https://api.studentrays.com/api/users/${id}`, {
          batch: updatedBatch,
        });

        console.log("updateResponse", updateResponse);
        // setAllbatch(updatedBatch);
      } else {
        console.log("Batch already exists in currentBatch. Skipping update.");
      }
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButton">Edit</div>
            <h1 className="title">Information</h1>
            <div className="item">
              <img
                // src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                src={data.img}
                alt=""
                className="itemImg"
              />
              <div className="details">
                {/* <h1 className="itemTitle">Jane Doe</h1> */}
                <h1 className="itemTitle">{data.username}</h1>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{data.email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">{data.phone}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Grade:</span>
                  <span className="itemValue">{data.grade}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Country:</span>
                  <span className="itemValue">{data.country}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" />
          </div>
        </div>
        <div className="bottom">
          {/* <h1 className="title">Last Transactions</h1>
          <List data={data} id={id} /> */}

          <TableContainer component={Paper} className="table">
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/* <TableCell className="tableCell">ID</TableCell> */}
                  <TableCell className="tableCell">Username</TableCell>
                  {/* <TableCell className="tableCell">Email</TableCell> */}
                  <TableCell className="tableCell">IsStudent</TableCell>
                  <TableCell className="tableCell">IsAdmin</TableCell>
                  <TableCell className="tableCell">IsTeacher</TableCell>
                  <TableCell className="tableCell">IsCounsellor</TableCell>
                  <TableCell className="tableCell">New Student</TableCell>
                  <TableCell className="tableCell">New Teacher</TableCell>
                  <TableCell className="tableCell">New Admin</TableCell>
                  <TableCell className="tableCell">New Counsellor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {/* <TableCell className="tableCell">{data._id}</TableCell> */}
                  <TableCell className="tableCell">
                    <div className="cellWrapper">{data.username}</div>
                  </TableCell>
                  {/* <TableCell className="tableCell">{data.email}</TableCell> */}
                  <TableCell className="tableCell">
                    {data.isStudent ? "true" : "false"}
                  </TableCell>
                  <TableCell className="tableCell">
                    {data.isAdmin ? "true" : "false"}
                  </TableCell>
                  <TableCell className="tableCell">
                    {data.isTeacher ? "true" : "false"}
                  </TableCell>
                  <TableCell className="tableCell">
                    {data.isCounsellor ? "true" : "false"}
                  </TableCell>
                  <TableCell className="tableCell">
                    <button type="submit" onClick={handleCreateStudent}>
                      Make Student
                    </button>
                  </TableCell>
                  <TableCell className="tableCell">
                    <button type="submit" onClick={handleCreateTeacher}>
                      Make Teacher
                    </button>
                  </TableCell>
                  <TableCell className="tableCell">
                    <button type="submit" onClick={handleCreateAdmin}>
                      Make Admin
                    </button>
                  </TableCell>
                  <TableCell className="tableCell">
                    <button type="submit" onClick={handleCreateCounsellor}>
                      Make Counsellor
                    </button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <p>all current batch </p>
          {allbatch && allbatch.map((batch) =>
          
           <span>{batch} |</span>
           )}
          <button onClick={() => setAllbatch(data.batch)}>Check</button>

          <h1>Assign Section</h1>
          {/* <Form>
            {["checkbox"].map((type) => (
              <div key={`inline-${type}`} className="mb-3">
                <Form.Check
                  inline
                  label="A"
                  name="group1"
                  type={type}
                  id={`inline-${type}-1`}
                  value="A"
                  onChange={handleCheckboxChange}
                />
                <Form.Check
                  inline
                  label="B"
                  name="group1"
                  type={type}
                  id={`inline-${type}-2`}
                  value="B"
                  onChange={handleCheckboxChange}
                />
                <Form.Check
                  inline
                  label="C"
                  name="group1"
                  type={type}
                  id={`inline-${type}-3`}
                  value="C"
                  onChange={handleCheckboxChange}
                />
                
              </div>
            ))}
          </Form> */}
          <Form onSubmit={handleSubmit}>
            {["radio"].map((type) => (
              <div key={`inline-${type}`} className="mb-3">
                <Form.Check
                  inline
                  label="ICSE"
                  name="course"
                  type={type}
                  id={`inline-${type}-1`}
                  value="icse"
                />
                <Form.Check
                  inline
                  label="CBSE"
                  name="course"
                  type={type}
                  id={`inline-${type}-2`}
                  value="cbse"
                />
              </div>
            ))}
            <hr />
            {["radio"].map((type) => (
              <div key={`inline-${type}`} className="mb-3">
                <Form.Check
                  inline
                  label="6"
                  name="grade"
                  type={type}
                  id={`inline-${type}-1`}
                  value="6"
                />
                <Form.Check
                  inline
                  label="7"
                  name="grade"
                  type={type}
                  id={`inline-${type}-1`}
                  value="7"
                />
                <Form.Check
                  inline
                  label="8"
                  name="grade"
                  type={type}
                  id={`inline-${type}-1`}
                  value="8"
                />
                <Form.Check
                  inline
                  label="9"
                  name="grade"
                  type={type}
                  id={`inline-${type}-1`}
                  value="9"
                />
                <Form.Check
                  inline
                  label="10"
                  name="grade"
                  type={type}
                  id={`inline-${type}-1`}
                  value="10"
                />
              </div>
            ))}
            <hr />
            {["radio"].map((type) => (
              <div key={`inline-${type}`} className="mb-3">
                <Form.Check
                  inline
                  label="Science"
                  name="subject"
                  type={type}
                  id={`inline-${type}-1`}
                  value="sci"
                />
                <Form.Check
                  inline
                  label="Maths"
                  name="subject"
                  type={type}
                  id={`inline-${type}-2`}
                  value="mat"
                />
              </div>
            ))}
            <hr />
            {["radio"].map((type) => (
              <div key={`inline-${type}`} className="mb-3">
                <Form.Check
                  inline
                  label="A"
                  name="section"
                  type={type}
                  id={`inline-${type}-1`}
                  value="a"
                />
                <Form.Check
                  inline
                  label="B"
                  name="section"
                  type={type}
                  id={`inline-${type}-2`}
                  value="b"
                />
                <Form.Check
                  inline
                  label="C"
                  name="section"
                  type={type}
                  id={`inline-${type}-3`}
                  value="c"
                />
              </div>
            ))}
            <hr />

            <button type="submit">Submit </button>
          </Form>

          {/* <button onClick={handleButtonClick}>Save Teacher Section </button> */}
        </div>
      </div>
    </div>
  );
};

export default SingleTeacher;
