
// import "./new.scss";
// import Sidebar from "../../components/sidebar/Sidebar";
// import Navbar from "../../components/navbar/Navbar";
// import { useState } from "react";
// import axios from "axios";
// import { useLocation } from "react-router-dom";

// const NewDetail = ({ inputs, title }) => {
//   const location = useLocation();
//   const idnew = location.pathname.split("/")[3];
//   const [info, setInfo] = useState({});
//   const [errors, setErrors] = useState({});

//   const handleChange = (e) => {
//     setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
//     // Clear error for this field when user starts typing
//     setErrors((prev) => ({ ...prev, [e.target.id]: '' }));
//   };

//   const validateForm = () => {
//     let isValid = true;
//     let newErrors = {};

//     inputs.forEach((input) => {
//       if (input.required && !info[input.id]) {
//         newErrors[input.id] = "This field is required";
//         isValid = false;
//       }
//     });

//     setErrors(newErrors);
//     return isValid;
//   };

//   const handleClick = async (e) => {
//     e.preventDefault();
//     if (validateForm()) {
//       try {
//         const newDetails = { ...info };
//         await axios.post("https://api.studentrays.com/api/details/" + idnew, newDetails);
//         const baseUrl = window.location.origin;
//         const urlToNavigate = `${baseUrl}/datas`;
//         window.location.href = urlToNavigate;
//       } catch (err) {
//         console.log(err);
//       }
//     } else {
//       console.log("Form validation failed");
//     }
//   };

//   return (
//     <div className="new">
//       <Sidebar />
//       <div className="newContainer">
//         <Navbar />
//         <div className="top">
//           <h1>{title}</h1>
//         </div>
//         <div className="bottom">
//           <div className="right">
//             <form onSubmit={handleClick}>
//               {inputs.map((input) => (
//                 <div className="formInput" key={input.id}>
//                   <label>{input.label}</label>
//                   <input
//                     onChange={handleChange}
//                     type={input.type}
//                     placeholder={input.placeholder}
//                     id={input.id}
//                     required
//                   />
//                   {errors[input.id] && <span className="error">{errors[input.id]}</span>}
//                 </div>
//               ))}
//               <button type="submit">Send</button>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default NewDetail;
import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const NewDetail = ({ inputs, title }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const idnew = location.pathname.split("/")[3];
  const [info, setInfo] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    setErrors((prev) => ({ ...prev, [e.target.id]: '' }));
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors = {};

    inputs.forEach((input) => {
      if (input.required && !info[input.id]) {
        newErrors[input.id] = "This field is required";
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleClick = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const newDetails = { ...info };
        const response = await axios.post("https://api.studentrays.com/api/details/" + idnew, newDetails);
        
        if (response.status === 200 || response.status === 201) {
          alert("Data submitted successfully!");
          navigate(-1); // This will navigate to the previous page
        }
      } catch (err) {
        console.log(err);
        alert("An error occurred while submitting the data.");
      }
    } else {
      console.log("Form validation failed");
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form onSubmit={handleClick}>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    onChange={handleChange}
                    type={input.type}
                    placeholder={input.placeholder}
                    id={input.id}
                    required
                  />
                  {errors[input.id] && <span className="error">{errors[input.id]}</span>}
                </div>
              ))}
              <button type="submit">Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewDetail;