import React from "react";
import "./chapterdetail.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import useFetch from "../../hooks/useFetch";

const Topic = ({ chid, id, onDelete }) => {
  const { data, loading, error } = useFetch(`https://api.studentrays.com/api/subtopics/${id}`);

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this topic?")) {
      try {
        const resp =await axios.delete(`https://api.studentrays.com/api/subtopics/${chid}/${id}`);
        console.log("resp",resp);
        onDelete(id);
      } catch (err) {
        console.error("Error deleting data:", err);
        alert("Failed to delete topic. Please try again.");
      }
    }
  };

  if (loading) return null;
  if (error) return <TableRow><TableCell colSpan={5}>Error: {error.message}</TableCell></TableRow>;

  return (
    <TableRow>
      <TableCell className="tableCell">
        <div className="cellWrapper">{data.topic}</div>
      </TableCell>
      <TableCell className="tableCell">
        <div className="cellWrapper">{data._id}</div>
      </TableCell>
      <TableCell className="tableCell">
        <div className="cellWrapper">{data.text}</div>
      </TableCell>
      <TableCell className="tableCell">
        <Link
          to={`/datas/chapter/subtopic/${chid}/${data._id}`}
          style={{ textDecoration: "none" }}
        >
          <div className="chapterviewButton">View</div>
        </Link>
      </TableCell>
      <TableCell className="tableCell">
        <div
          className="chapterdeleteButton"
          onClick={handleDelete}
        >
          Delete
        </div>
      </TableCell>
    </TableRow>
  );
};

export default Topic;
