import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import axios from "axios";

const Datatable = ({ columns }) => {
  console.log("columns", columns);
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const paths = location.pathname.split("/")[2];
  const [list, setList] = useState([]);
  const { data, loading, error } = useFetch(`https://api.studentrays.com/api/${path}`);
  console.log("Datatable Data", data);
  console.log("paths", paths);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (data) {
      let filtered;
      if (paths === "teachers") {
        filtered = data.filter((row) => row.isTeacher === true);
      } else if (paths === "counsellors") {
        filtered = data.filter((row) => row.isCounsellor === true);
      } else {
        filtered = data.filter((row) => row.isStudent === true);
      }
      setFilteredData(filtered);
      setList(filtered);
    }
  }, [data, paths]);

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        await axios.delete(`https://api.studentrays.com/api/${path}/${id}`);
        setList(list.filter((item) => item._id !== id));
        setFilteredData(filteredData.filter((item) => item._id !== id));
      } catch (err) {
        console.error("Error deleting item:", err);
      }
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {paths === "teachers" ? (
              <Link
                to={`/${path}/${paths}/${params.row._id}`}
                style={{ textDecoration: "none" }}
              >
                <div className="viewButton">View</div>
              </Link>
            ) : paths === "students" || paths === "counsellors" ? (
              <Link
                to={`/${path}/${params.row._id}`}
                style={{ textDecoration: "none" }}
              >
                <div className="viewButton">View</div>
              </Link>
            ) : null}
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row._id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="datatable">
      <div className="datatableTitle">
        {path}
        <Link to={`/${path}/new`} className="link">
          Add New
        </Link>
      </div>
      {filteredData.length > 0 ? (
        <DataGrid
          className="datagrid"
          rows={filteredData}
          columns={columns.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}
          checkboxSelection
          getRowId={(row) => row._id}
        />
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

export default Datatable;